unit uRegistration;

interface

uses
  System.SysUtils,
  System.Classes,
  JS,
  Web,
  WEBLib.Graphics,
  WEBLib.Controls,
  WEBLib.Forms,
  WEBLib.Dialogs,
  Vcl.Controls,
  WEBLib.StdCtrls,
  Vcl.StdCtrls,
  Wrapper.Person,
  XData.Web.Connection,
  XData.Web.Client,
  System.Generics.Collections,
  WEBLib.JSON,
  intf.Countries,
  intf.States,
  intf.PostalCodes,
  intf.Cities,
  intf.CompanyPositions,
  intf.SeniorityRoles,
  uDMStripe,
  Wrapper.Lookups,
  WEBLib.REST,
  WebLib.WebTools,
  WEBLib.ExtCtrls,
  WEBLib.WebCtrls,
  Vcl.Imaging.jpeg,

  Data_UserProfile,

  uEmail,
  uCookies;

type
  TRegistrationFrm = class(TForm)
    txtFirstName: TEdit;
    txtJobTitle: TComboBox;
    txtPassword: TEdit;
    txtSurname: TEdit;
    btnApply: TButton;
    txtCountry: TComboBox;
    txtCompanyName: TEdit;
    txtPromoCode: TEdit;
    txtZip: TEdit;
    txtEmail: TEdit;
    txtCity: TComboBox;
    txtAddress: TEdit;
    txtState: TComboBox;
    txtSeniority: TComboBox;
    txtReTypePassword: TEdit;
    txtMobile: TEdit;
    XDataWebClient: TXDataWebClient;
    APIIdentification: TXDataWebConnection;
    btnSave: TButton;
    WebHttpRequest1: THttpRequest;
    layLeftNavContainer: TPanel;
    WebPanel5: TPanel;
    WebPanel6: TPanel;
    lblLogin2: TLabel;
    imgTesting: THTMLDiv;
    WebPanel7: TPanel;
    lblTMLCReviews2: TLabel;
    WebHTMLDiv2: THTMLDiv;
    WebPanel8: TPanel;
    lblTMLCAssessment2: TLabel;
    WebHTMLDiv3: THTMLDiv;
    WebPanel9: TPanel;
    lblTheMarketingLeadersCodeBook2: TLabel;
    WebHTMLDiv4: THTMLDiv;
    WebPanel10: TPanel;
    lblOurExperience2: TLabel;
    WebHTMLDiv5: THTMLDiv;
    WebPanel11: TPanel;
    lblOurApproach2: TLabel;
    WebHTMLDiv6: THTMLDiv;
    WebPanel12: TPanel;
    lblContact2: TLabel;
    WebHTMLDiv7: THTMLDiv;
    WebPanel13: TPanel;
    lblBlog2: TLabel;
    WebHTMLDiv8: THTMLDiv;
    WebPanel14: TPanel;
    WebHTMLDiv9: THTMLDiv;
    TopBar: TPanel;
    imgLogo: TImageControl;
    lblLogin: TLabel;
    lblTheMarketingLeadersCodeBook: TLabel;
    lblTMLCAssessment: TLabel;
    lblTMLCReviews: TLabel;
    lblBlog: TLabel;
    lblContact: TLabel;
    lblOurApproach: TLabel;
    lblOurExperience: TLabel;
    btnHamburgerMenu: THTMLDiv;
    lblWelcomeUser: TLabel;
    imgProfilePicture: TImageControl;
    layHeaderProfileDropDown: TPanel;
    layNavSignOut: TPanel;
    btnSignOut: TPanel;
    WebLabel10: TLabel;
    WebHTMLDiv12: THTMLDiv;
    layNavProfile: TPanel;
    btnGoToDashboard: TPanel;
    WebLabel11: TLabel;
    WebHTMLDiv13: THTMLDiv;
    GetUser: THttpRequest;
    XDataWebClient1: TXDataWebClient;
    CreateSubscription: THttpRequest;
    procedure WebFormDestroy(Sender: TObject);
    procedure WebFormCreate(Sender: TObject);
    procedure APIIdentificationError(Error: TXDataWebConnectionError);
    procedure APIIdentificationRequest(Args: TXDataWebConnectionRequest);
    procedure btnApplyClick(Sender: TObject);
    procedure btnSaveClick(Sender: TObject);
    procedure txtSeniorityChange(Sender: TObject);
    procedure WebFormShow(Sender: TObject);
    procedure WebHttpRequest1Response(Sender: TObject; AResponse: string);
    procedure XDataWebClientError(Error: TXDataClientError);
    procedure XDataWebClientRequest(Request: TXDataClientRequest);
    procedure WebFormResize(Sender: TObject);
    procedure btnHamburgerMenuClick(Sender: TObject);
    procedure imgLogoClick(Sender: TObject);
    procedure LinkMouseEnter(Sender: TObject);
    procedure LinkMouseLeave(Sender: TObject);
    procedure CloseMobileNav(Sender: TObject);
    procedure lblLoginClick(Sender: TObject);
    procedure lblContactClick(Sender: TObject);
    procedure lblBlogClick(Sender: TObject);
    procedure lblTMLCReviewsClick(Sender: TObject);
    procedure lblTMLCAssessmentClick(Sender: TObject);
    procedure lblTheMarketingLeadersCodeBookClick(Sender: TObject);
    procedure lblOurExperienceClick(Sender: TObject);
    procedure lblOurApproachClick(Sender: TObject);
    procedure OpenHeaderProfileDropDown(Sender: TObject);
    procedure btnSignOutClick(Sender: TObject);
    procedure btnDashboardMouseEnter(Sender: TObject);
    procedure btnDashboardMouseLeave(Sender: TObject);
    procedure btnGoToDashboardClick(Sender: TObject);
    procedure WebButton1Click(Sender: TObject);
  private
    { Private declarations }
    FCheckout: TCheckout;

    FCities:  TDictionary<Integer, String>;
    FStates:  TDictionary<Integer, String>;
    FCountry: TDictionary<Integer, String>;
    FTitles:  TDictionary<Integer, String>;
    FSeniority : TDictionary<Integer, String>;
    FSeniorityRolesList   : TList<TSeniorityRolesClassWrapper>;

    FLookupsWrapper: TLookupsWrapper;
    FProductId : Integer;
    FIsFree  : boolean;


    procedure InvokeAPICall(Service: String);
    procedure ConnectServer;
    procedure PopulateComboxes(AJsonData: String);
    procedure GetUrlParams;
    procedure GetCountry;
    procedure CalculateVAT;
    procedure GetCountries(Service: String);
    procedure GetCompanyPositions(Service: String);
    procedure CreateSubscriptions;

    function FindKeyByValue(Map: TDictionary<Integer, String>; const AValue: String): Integer;
    function GetActiveBaseURL: String;
    function ExtractCountryCode(const ResponseText: string): string;

  public
    { Public declarations }
    FCurrency : String;
    FCountryCode: String;
  protected procedure LoadDFMValues; override; end;

var
  RegistrationFrm: TRegistrationFrm;

implementation

uses Custom.Enumerations;

{$R *.dfm}

procedure TRegistrationFrm.WebFormDestroy(Sender: TObject);
begin
  FCities.Free;
  FStates.Free;
  FCountry.Free;
  FTitles.Free;
  FSeniority.Free;
  FSeniorityRolesList.Free;

end;

procedure TRegistrationFrm.WebFormResize(Sender: TObject);
begin
  layLeftNavContainer.Visible := False;

  if (RegistrationFrm.Width > 1600) then
  begin
    lblWelcomeUser.Font.Size := 15;
    lblLogin.Font.Size := 15;
    lblContact.Font.Size := 15;
    lblBlog.Font.Size := 15;
    lblTMLCReviews.Font.Size := 15;
    lblTMLCAssessment.Font.Size := 15;
    lblTheMarketingLeadersCodeBook.Font.Size := 15;
    lblOurExperience.Font.Size := 15;
    lblOurApproach.Font.Size := 15;
    imgLogo.Margins.Left := 99;
    imgLogo.Margins.Top := 0;
    imgLogo.Margins.Bottom := 0;
  end else if (RegistrationFrm.Width > 1400) then
  begin
    lblWelcomeUser.Font.Size := 12;
    lblLogin.Font.Size := 12;
    lblContact.Font.Size := 12;
    lblBlog.Font.Size := 12;
    lblTMLCReviews.Font.Size := 12;
    lblTMLCAssessment.Font.Size := 12;
    lblTheMarketingLeadersCodeBook.Font.Size := 12;
    lblOurExperience.Font.Size := 12;
    lblOurApproach.Font.Size := 12;
    imgLogo.Margins.Left := 50;
    imgLogo.Margins.Top := 0;
    imgLogo.Margins.Bottom := 0;
  end else
  begin
    lblWelcomeUser.Font.Size := 11;
    lblLogin.Font.Size := 11;
    lblContact.Font.Size := 11;
    lblBlog.Font.Size := 11;
    lblTMLCReviews.Font.Size := 11;
    lblTMLCAssessment.Font.Size := 11;
    lblTheMarketingLeadersCodeBook.Font.Size := 11;
    lblOurExperience.Font.Size := 11;
    lblOurApproach.Font.Size := 11;
    imgLogo.Margins.Left := 50;
    imgLogo.Margins.Top := 5;
    imgLogo.Margins.Bottom := 5;
  end;

  if (RegistrationFrm.Width > 1150) then
  begin
    btnHamburgerMenu.Visible := False;


    lblLogin.Visible := not(lblWelcomeUser.Visible);
    lblContact.Visible := True;
    lblBlog.Visible := True;
    lblTMLCReviews.Visible := True;
    lblTMLCAssessment.Visible := True;
    lblTheMarketingLeadersCodeBook.Visible := True;
    lblOurExperience.Visible := True;
    lblOurApproach.Visible := True;
  end else
  begin
    btnHamburgerMenu.Visible := True;
    lblLogin.Visible := False;
    lblContact.Visible := False;
    lblBlog.Visible := False;
    lblTMLCReviews.Visible := False;
    lblTMLCAssessment.Visible := False;
    lblTheMarketingLeadersCodeBook.Visible := False;
    lblOurExperience.Visible := False;
    lblOurApproach.Visible := False;
  end;
end;

procedure TRegistrationFrm.WebButton1Click(Sender: TObject);
begin
 CreateSubscriptions;
end;

procedure TRegistrationFrm.WebFormCreate(Sender: TObject);
  procedure SetLinksForAPI();
  const
    ReplaceFrom: String = 'https://sideminds.com';
    ReplaceTo: String = 'https://sideminds.com';
    
  begin
    if (LowerCase(document.documentURI).contains('localhost')) then
    begin
      GetUser.URL := GetUser.URL.replace(ReplaceFrom,ReplaceTo);
    end;
  end;

var
  CountryCode: String;
  btnSaveHTML: String;
begin
  SetLinksForAPI();

  GetUrlParams;
  GetCountry;
  FCities         := TDictionary<Integer, String>.create;
  FStates         := TDictionary<Integer, String>.create;
  FCountry        := TDictionary<Integer, String>.create;
  FTitles         := TDictionary<Integer, String>.create;
  FSeniority      := TDictionary<Integer, String>.create;
  //FCountryCode    := CountryCode;
  FLookupsWrapper := TLookupsWrapper.create;;
  FSeniorityRolesList := TList<TSeniorityRolesClassWrapper>.create;

  ConnectServer;

  TopBar.ElementHandle.style.setProperty('box-shadow','0 4px 5px rgba(74, 74, 74, 0.34)');


  if (GetQueryParam('pr') = 'Free') then
    document.getElementById('layPaymentInfo').setAttribute('style','display:none');

  UserProfile_Username := GetCookie('username').Trim;

  if (UserProfile_Username.Length > 0) then
  begin
    GetUser.Headers.Clear;
    GetUser.Headers.AddPair('Content-Type','application/json');
    GetUser.Headers.AddPair('tenant-url','https://sideminds.com/');
    GetUser.Headers.AddPair('runningmode','rmDevelopment');
    GetUser.PostData := '{"username": "' + UserProfile_Username + '"}';

    document.getElementById('layRegistrationDetails').setAttribute('style','display:none');
    btnSaveHTML := document.getElementById('layButtonSave').innerHTML;
    document.getElementById('layButtonSave').innerHTML := '';
    document.getElementById('layCartRegister').innerHTML := btnSaveHTML;
    document.getElementById('btnSave').addEventListener('click', @btnSaveClick);

    GetUser.Execute(

      procedure(AResponse: string; AReq: TJSXMLHttpRequest)
      var
        JS: TJSObject;
      begin
        JS := TJSJSON.parseObject(AResponse);


        console.log('GetUser: ');
        console.log(JS);
        console.log('GetUser UserId: ');
        console.log(JS.Properties['UserId']);

        lblLogin.Visible := False;
        imgProfilePicture.Visible := True;
        lblWelcomeUser.Visible := True;

        UserProfile_FirstName := String(JS.Properties['Firstname']);
        UserProfile_LastName := String(JS.Properties['Surname']);
        UserProfile_Email := String(JS.Properties['Email']);
        UserProfile_MobileNumber := String(JS.Properties['ContactDetail']);
        UserProfile_Gender := String(JS.Properties['Gender']);
        UserProfile_CompanyName := String(JS.Properties['CompanyName']);
        UserProfile_UserId := UInt64(JS.Properties['UserId']);
        UserProfile_JobTitle := String(JS.Properties['JobTitle']);
        UserProfile_Role := String(JS.Properties['SurveyRole']);

        txtFirstName.Text := UserProfile_FirstName;
        txtSurname.Text := UserProfile_LastName;
        txtCompanyName.Text := UserProfile_CompanyName;
        txtEmail.Text := UserProfile_Email;
        txtMobile.Text := UserProfile_MobileNumber;
        txtJobTitle.Text := UserProfile_JobTitle;

        lblWelcomeUser.Caption := 'Welcome ' + UserProfile_FirstName;

        asm
          checkRequiredFields();
          window.dispatchEvent(new Event('resize'));
        end;
      end
    );
  end;

end;

procedure TRegistrationFrm.APIIdentificationError(Error: TXDataWebConnectionError);
begin
  console.log(Error.ErrorMessage);
end;

procedure TRegistrationFrm.APIIdentificationRequest(Args: TXDataWebConnectionRequest);
begin
  Args.Request.headers.SetValue('tenant-url', 'https://sideminds.com/');
  Args.Request.headers.SetValue('runningmode', 'rmProduction');
end;

procedure TRegistrationFrm.btnApplyClick(Sender: TObject);
  procedure OnResult(Response: TXDataClientResponse);
  var
    JsonObject: TJSONObject;
    Tax : string;
    AmountDue : string;
    Discount : string;
    RetailPrice : string;
    Currency : string;
  begin
    JsonObject := TJSONObject.ParseJSONValue(Response.ResponseText) as TJSONObject;
    Currency := GetQueryParam('cur');
    try
      RetailPrice := FormatFloat('#0.00',StrToFloat(JsonObject.GetJSONValue('RetailPrice'))) ;
      Discount    := FormatFloat('#0.00',StrToFloat(JsonObject.GetJSONValue('Discount')));
      AmountDue   := FormatFloat('#0.00',StrToFloat(JsonObject.GetJSONValue('AmountDue')));
      Tax         := FormatFloat('#0.00',StrToFloat(JsonObject.GetJSONValue('Tax')));

      {$IFDEF PAS2JS}
         asm
          document.getElementById("SubTotal1").innerHTML = Currency + RetailPrice ;
          document.getElementById("TotalDue").innerHTML = Currency + AmountDue ;
          document.getElementById("TAX").innerHTML = Currency + Tax ;
          document.getElementById("Discount").innerHTML = Currency + Discount ;
         end;
      {$ENDIF}
    finally
      JsonObject.Free;
    end;
  end;
var
 CountryId : Integer;
begin
 if(txtEmail.Text = '') or (txtPromoCode.Text = '')  then
 begin
    ShowMessage('Please Insert the Promocode and Email')
 end
 else
 begin
    CountryId := FindKeyByValue(FCountry, txtCountry.Text);
    if (FCountryCode <> '' ) or (txtPromoCode.Text <> '') then
      //XDataWebClient.rawInvoke('ISidemindsService.CalculatePrice', [FProductId,CountryId,0,txtPromoCode.Text], @OnResult)
    else
      //ShowMessage('Country not found, please check connectivity and refresh!')
 end;
end;

procedure TRegistrationFrm.btnDashboardMouseEnter(Sender: TObject);
begin
  TPanel(Sender).Color := $00FFF0EC;
end;

procedure TRegistrationFrm.btnDashboardMouseLeave(Sender: TObject);
begin
  TPanel(Sender).Color := clNone;
end;

procedure TRegistrationFrm.btnGoToDashboardClick(Sender: TObject);
begin
  Application.Navigate('dashboard.html',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.btnHamburgerMenuClick(Sender: TObject);
begin
  layLeftNavContainer.Visible := not(layLeftNavContainer.Visible);
  layLeftNavContainer.ElementHandle.style.setProperty('background-color','rgba(60, 60, 60, 0.5)');
end;

procedure TRegistrationFrm.CreateSubscriptions;
var

  UserEmail, UserId, ProjectID, AssessmentType :string;
  CreatedDate, ReceiptURL, From, ReplyTo, CC, Subject, Receipient, Body: string;
begin

  //CreateSubscription := TWebHttpRequest.Create(nil);
  try

    CreateSubscription.URL := 'https://sideminds.com/empire/sideminds/SidemindsService/createsubscription';
    CreateSubscription.Headers.Clear;
    CreateSubscription.Headers.AddPair('Content-Type', 'application/json');
    CreateSubscription.Headers.AddPair('tenant-url', 'https://sideminds.cymeg.com/');
    CreateSubscription.Headers.AddPair('runningmode', 'rmDevelopment');
    CreateSubscription.Command := httpPOST;
    CreateSubscription.PostData := '{"Username": "' + txtEmail.Text + '","ProductID":"'+ IntToStr(FProductId) + '"}';
    //CreateSubscription.PostData := '{"Username": "walter@appzoola.com","ProductID":"1"}';


     CreateSubscription.Timeout := 10000;

     console.log('Response Passed : ONE');

     CreateSubscription.Execute(
      procedure(AResponse: string; AReq: TJSXMLHttpRequest)
      var
        JS: TJSObject;
      begin
        ShowMessage('Response Passed  : TWO');

        //JS := TJSJSON.parseObject(AResponse);


//        UserEmail := String(JS.Properties['Email']);
//        UserId := String(JS.Properties['UserId']);
//        ProjectID := String(JS.Properties['ProjectID']);
//        AssessmentType := String(JS.Properties['AssessmentType']);
//        CreatedDate := String(JS.Properties['CreatedDate']);

        //ShowMessage('Response Passed  : THREE');

      end

    );

//    CreateSubscription.Execute(
//      procedure(AResponse: string; AReq: TJSXMLHttpRequest)
//      var
//      JS: TJSONObject;
//      begin
//         ShowMessage('Response Passed  : TWO');
//        if AResponse <>'' then
//        begin
//
//
//          JS := TJSONObject.ParseJSONValue(AResponse) as TJSONObject;
//
//          //try
//            if JS <> nil then
//            begin
//
//              UserEmail := Js.GetValue('Email').Value;
//              UserId := Js.GetValue('UserId').Value;
//              ProjectID := Js.GetValue('ProjectID').Value;
//              AssessmentType := Js.GetValue('AssessmentType').Value;
//              CreatedDate := Js.GetValue('CreatedDate').Value;
//
//               console.log(UserEmail);
//               From :='gareth.helm@sideminds.cymeg.com';
//
//               ReplyTo := 'support@sideminds.com';
//
//               ReceiptURL :='https://reports.appzoola.com/download/pdf?clientId=sideminds&reportName=receipt&userId='+Userid+'&projectId='+Projectid;
//
//               Subject :='Receipt for '+AssessmentType+' purchased on '+CreatedDate;
//
//               Receipient := UserEmail;
//
//               CC :='';
//
//               Body :='Thank you for buying the '+AssessmentType+' from Sideminds Ltd. <br><br>'+
//                         'Click on the link below to download your receipt.<br>'+
//                         ''+ReceiptURL+'<br><br>'+
//                         'Please remember to complete your assessment within 10 days. For support or further enquires please contact support@sideminds.com.<br><br>'+
//                         'Best wishes,<br>'+
//                         'Gareth Helm,<br>'+
//                         'Founder Sideminds Ltd, <br>'+
//                         'Author of The Marketing Leaders CODE';
//
//              SendEmail(From, ReplyTo, Subject, Body, Receipient, CC);
//
//
//            end
//            else
//            begin
//              ShowMessage('Error: Invalid JSON.');
//            end;
//         // finally
//         //   Js.Free;
//         // end;
//        end
//        else
//        begin
//
//          ShowMessage('Error: ' + IntToStr(AReq.Status) + ' - ' + AReq.StatusText);
//        end;
//      end
//    );
  finally
    CreateSubscription.Free;
  end;
end;


procedure TRegistrationFrm.btnSaveClick(Sender: TObject);
var


  Person:     TPersonContainer;
  JsonObject: TJSONObject;

  UserEmail, UserId, ProjectID, AssessmentType :string;
  CreatedDate, ReceiptURL, From, ReplyTo, CC, Subject, Receipient, Body: string;

  procedure OnResult(Response: TXDataClientResponse);
  var
    JSONData:   String;
    JsonObject: TJSONObject;
    Respond: TXDataClientResponse;
  begin
    JsonObject := TJSONObject.ParseJSONValue(Response.ResponseText) as TJSONObject;
    JSONData   := JsonObject.GetJSONValue('value');
    if (JSONData <> '0') and (JSONData <> '-1' ) then
    begin
      if (FProductId = Ord(aMiniAssessment)) then
      begin
       XDataWebClient.rawInvoke('ISidemindsService.CreateSubscription', [txtEmail.Text, FProductId]);
      end;
      {$IFDEF PAS2JS}
      asm
          const searchParams = new URLSearchParams(window.location.search);
          if(searchParams.has('pr')){
            const price = searchParams.get('pr');
            if(price == 'Free'){
               var usrname = document.getElementById("txtEmail").value;
               document.cookie = "username=" + usrname
               setTimeout(function(){
                  document.getElementsByClassName("loading")[0].style.display = "none";
                  window.location.href ="https://sideminds.com/dashboard.html";
               }, 1000);
             }
            }
      end;
      {$ENDIF}

      ShowMessage('Please Use a laptop or desktop to Complete Assessments');

    end
    else
    begin
      ShowMessage('Error Registering User!')
    end;
  end;

begin
  {$IFDEF PAS2JS}
  asm
    const searchParams = new URLSearchParams(window.location.search);
    if(searchParams.has('pr')){
     const price = searchParams.get('pr');
     if(price == 'Free'){
        document.getElementsByClassName("loading")[0].style.display = "block";
     }
    } ;
  end;
  {$ENDIF}

  Person := TPersonContainer.create;
  try
    Person.Email             := txtEmail.Text;
    Person.Firstname         := txtFirstName.Text;
    Person.Surname           := txtSurname.Text;
    Person.password          := txtPassword.Text;
    Person.CompanyName       := txtCompanyName.Text;
    Person.ContactDetail     := txtMobile.Text;
    Person.AddressLine1      := txtAddress.Text;
    Person.CityID            := FindKeyByValue(FCities, txtCity.Text);
    Person.StateID           := FindKeyByValue(FStates, txtState.Text);
    Person.CountryID         := FindKeyByValue(FCountry, txtCountry.Text);
    Person.CompanyPositionId := FindKeyByValue(FTitles, txtJobTitle.Text);
    Person.SurveyRole        := 1;

    XDataWebClient.rawInvoke('ISidemindsService.RegisterNewUser', [Person], @OnResult);
  finally
    Person.Free;
    JsonObject.Free;
  end;

end;

procedure TRegistrationFrm.btnSignOutClick(Sender: TObject);
begin
  Application.Navigate('user-login.html', TNavigationTarget.ntPage);
end;

function TRegistrationFrm.GetActiveBaseURL: String;
var
  URL:           string;
  a:             TArray<string>;
  x:             Integer;
  urlh:          string;
  urlidentifier: string;
begin
  URL             := document.documentURI;
  a               := URL.Split(['/'], 4);
  urlh            := a[0];
  urlidentifier   := '';
  for x           := 2 to high(a) do
    urlidentifier := urlidentifier + '/' + a[x];
  urlh            := urlh + '/' + urlidentifier;
  result          := urlh + '/';
end;

procedure TRegistrationFrm.GetCountry;
begin
  WebHttpRequest1.URL     := 'https://www.cloudflare.com/cdn-cgi/trace';
  WebHttpRequest1.Command := httpGet;
  WebHttpRequest1.execute(
    procedure(auxResponse: string; ARequest: TJSXMLHttpRequest)
    begin
      if auxResponse <> '' then
      begin
        // console.log(auxResponse);
        // FCountryCode := ExtractCountryCode(auxResponse);
      end
    end);
end;

procedure TRegistrationFrm.XDataWebClientError(Error: TXDataClientError);
begin
  console.log(Error.ErrorMessage);
end;

procedure TRegistrationFrm.XDataWebClientRequest(Request: TXDataClientRequest);
begin
  Request.Request.headers.SetValue('tenant-url', 'https://sideminds.com/');
  Request.Request.headers.SetValue('runningmode', 'rmProduction');
end;

procedure TRegistrationFrm.CalculateVAT;
  procedure OnResult(Response: TXDataClientResponse);
  var
    JsonObject: TJSONObject;
    Tax : string;
    AmountDue : string;
    Discount : string;
    RetailPrice : string;
    Currency : string;
  begin
    JsonObject := TJSONObject.ParseJSONValue(Response.ResponseText) as TJSONObject;
    Currency := GetQueryParam('cur');
    try
      Tax := FormatFloat('#0.00',StrToFloat(JsonObject.GetJSONValue('Tax')));
      {$IFDEF PAS2JS}
         asm
          document.getElementById("TAX").innerHTML = Currency + Tax ;
         end;
      {$ENDIF}
    finally
      JsonObject.Free;
    end;
  end;
var
 CountryId : Integer;
begin
  CountryId := FindKeyByValue(FCountry, txtCountry.Text);
  if (FCountryCode <> '' ) then
    XDataWebClient.rawInvoke('ISidemindsService.CalculatePrice', [FProductId,CountryId,0,''], @OnResult)
end;

procedure TRegistrationFrm.CloseMobileNav(Sender: TObject);
begin
  layLeftNavContainer.Visible := False;
end;

procedure TRegistrationFrm.ConnectServer;
  procedure ConnectProc;
  begin
   GetCountries('ISidemindsService.GetCountries');
   GetCompanyPositions('ISidemindsService.GetSeniorityRoles');
  end;

begin
  APIIdentification.Close;
  APIIdentification.URL := '/empire/sideminds';
  APIIdentification.open(@ConnectProc);
end;

procedure TRegistrationFrm.InvokeAPICall(Service: String);
  procedure OnResult(Response: TXDataClientResponse);
  begin
    PopulateComboxes(Response.ResponseText);
    CalculateVAT;
  end;

begin
  if (FCountryCode <> '') then
    XDataWebClient.rawInvoke(Service, [FCountryCode], @OnResult)
  else
    ShowMessage('Country not found, please check connectivity and refresh!')
end;

procedure TRegistrationFrm.lblBlogClick(Sender: TObject);
begin
  Application.Navigate('blog.html',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblContactClick(Sender: TObject);
begin
  Application.Navigate('https://sideminds.com/contact',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblLoginClick(Sender: TObject);
begin
  Application.Navigate('user-login.html',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblOurApproachClick(Sender: TObject);
begin
  Application.Navigate('https://sideminds.com/',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblOurExperienceClick(Sender: TObject);
begin
  Application.Navigate('https://sideminds.com/experience',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblTheMarketingLeadersCodeBookClick(Sender: TObject);
begin
  Application.Navigate('https://sideminds.com/marketing',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblTMLCAssessmentClick(Sender: TObject);
begin
  Application.Navigate('buy-assessment.html',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.lblTMLCReviewsClick(Sender: TObject);
begin
  Application.Navigate('reviews.html',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.LinkMouseEnter(Sender: TObject);
begin
  TLabel(Sender).ElementHandle.style.setProperty('text-decoration-line','underline');
  TLabel(Sender).ElementHandle.style.setProperty('text-decoration-color','red');
end;

procedure TRegistrationFrm.LinkMouseLeave(Sender: TObject);
begin
  TLabel(Sender).ElementHandle.style.setProperty('text-decoration-line','none');
end;

procedure TRegistrationFrm.OpenHeaderProfileDropDown(Sender: TObject);
begin
  layHeaderProfileDropDown.Visible := True;
end;

procedure TRegistrationFrm.PopulateComboxes(AJsonData: String);
var
  JSONData:   String;
  JsonObject: TJSONObject;
  I:          Integer;
  x:          Integer;
  J:          Integer;
  JSONValue:  TJSONObject;
  JValue:     TJSONObject;
  SeniorityRole : TSeniorityRolesClassWrapper;
begin
  JSONData   := AJsonData;
  JsonObject := TJSONObject.ParseJSONValue(JSONData) as TJSONObject;
  try

    for I := 0 to JsonObject.Count - 1 do
    begin
      JSONValue := TJSONObject(JsonObject.Pairs[I].JSONValue);
      for J     := 0 to JSONValue.Count - 1 do
      begin

        JValue := TJSONObject(TJSONObject(JSONValue.Items[J]).GetValue('Countries'));
        for x  := 0 to JValue.Count - 1 do
        begin
          txtCountry.Items.Add(TJSONObject(JValue.Items[x]).GetValue('CountryName').value);
          FCountry.Add(StrToInt(TJSONObject(JValue.Items[x]).GetValue('CountryID').value), TJSONObject(JValue.Items[x]).GetValue('CountryName').value);
          if(FCountryCode = TJSONObject(JValue.Items[x]).GetValue('CountryCode').value) then
          begin
            txtCountry.ItemIndex := x;
          end;
        end;

        JValue := TJSONObject(TJSONObject(JSONValue.Items[J]).GetValue('States'));
        for x  := 0 to JValue.Count - 1 do
        begin
          FStates.Add(StrToInt(TJSONObject(JValue.Items[x]).GetValue('fStateId').value), TJSONObject(JValue.Items[x]).GetValue('fStateName').value);
          txtState.Items.Add(TJSONObject(JValue.Items[x]).GetValue('fStateName').value);
        end;

        JValue := TJSONObject(TJSONObject(JSONValue.Items[J]).GetValue('Cities'));
        for x  := 0 to JValue.Count - 1 do
        begin
          txtCity.Items.Add(TJSONObject(JValue.Items[x]).GetValue('CityName').value);
          FCities.Add(StrToInt(TJSONObject(JValue.Items[x]).GetValue('CityId').value), TJSONObject(JValue.Items[x]).GetValue('CityName').value);
        end;

        JValue := TJSONObject(TJSONObject(JSONValue.Items[J]).GetValue('CompanyPositionsCodes'));
        for x  := 0 to JValue.Count - 1 do
        begin
          FTitles.Add(StrToInt(TJSONObject(JValue.Items[x]).GetValue('CompanyPositionId').value), TJSONObject(JValue.Items[x]).GetValue('Title').value);
          txtJobTitle.Items.Add(TJSONObject(JValue.Items[x]).GetValue('Title').value);
        end;

        JValue := TJSONObject(TJSONObject(JSONValue.Items[J]).GetValue('SeniorityRoles'));
        for x  := 0 to JValue.Count - 1 do
        begin
          SeniorityRole := TSeniorityRolesClassWrapper.Create;
          SeniorityRole.SeniorityId := StrToInt(TJSONObject(JValue.Items[x]).GetValue('SeniorityId').value);
          SeniorityRole.Seniority   := TJSONObject(JValue.Items[x]).GetValue('Seniority').value;
          SeniorityRole.JobTitleId  := StrToInt(TJSONObject(JValue.Items[x]).GetValue('JobTitleId').value);
          SeniorityRole.JobTitle    := TJSONObject(JValue.Items[x]).GetValue('JobTitle').value;
          if(not FSeniority.ContainsKey(SeniorityRole.SeniorityId)) then
          begin
            FSeniority.Add(StrToInt(TJSONObject(JValue.Items[x]).GetValue('SeniorityId').value), TJSONObject(JValue.Items[x]).GetValue('Seniority').value);
            txtSeniority.Items.Add(TJSONObject(JValue.Items[x]).GetValue('Seniority').value);
          end;
          FSeniorityRolesList.add(SeniorityRole);
        end;

      end;
    end;
  finally
    JsonObject.Free;
  end;
end;

function TRegistrationFrm.FindKeyByValue(Map: TDictionary<Integer, String>; const AValue: String): Integer;
var
  Pair: TPair<Integer, String>;
begin
  if(AValue <> '') then
  begin
    for Pair in Map do
    begin
       if (Pair.value = AValue) then
       begin
         Exit(Pair.Key);
       end;
    end;
  end
  else
   Exit(0);
  raise Exception.create('Key not found for value: ' + AValue);
end;

function TRegistrationFrm.ExtractCountryCode(const ResponseText: string): string;
var
  Lines:  TStringList;
  Line:   string;
  LocPos: Integer;
begin
  result := '';
  Lines  := TStringList.create;
  try
    Lines.Text := ResponseText;
    for Line in Lines do
    begin
      LocPos := Pos('loc=', Line);
      if LocPos = 1 then
      begin
        result := Copy(Line, LocPos + Length('loc='), MaxInt);
        Break;
      end;
    end;
  finally
    Lines.Free;
  end;
end;

procedure TRegistrationFrm.WebFormShow(Sender: TObject);
begin
 GetUrlParams;
{$IFDEF PAS2JS}
  asm
    document.getElementsByClassName("loading")[0].style.display = "none";
    window.dispatchEvent(new Event('resize'));
//    checkRequiredFields();
  end;
{$ENDIF}
end;

procedure TRegistrationFrm.WebHttpRequest1Response(Sender: TObject; AResponse: string);
begin
  FCountryCode := ExtractCountryCode(AResponse);
end;


procedure TRegistrationFrm.GetUrlParams;
var
 Amount : string;
 FormattedAmount : String;
 Value: Double;
 Currency : string;
begin
  Currency := GetQueryParam('cur');
  Amount   := GetQueryParam('pr');

  if(Amount = '')then
  begin
    Amount := '10';
    Currency := '£';
  end;
  if TryStrToFloat(Amount, value) then
  begin
    FormattedAmount := FormatFloat('#0.00', value);
  end;

  if (Amount = '49') then
   FProductId :=  Ord(aFullAssessDigital)
  else if (Amount = '129') then
   FProductId :=  Ord(aFullAssessVidDebrief)
  else if(Amount = 'Free')  then
  begin
   FIsFree  := true;
   FProductId := Ord(aMiniAssessment);
   FormattedAmount := FormatFloat('#0.00', 0);
  end;
  {$IFDEF PAS2JS}
  asm
    document.getElementById("SubTotal1").innerHTML = Currency + FormattedAmount ;
    document.getElementById("TotalDue").innerHTML = Currency + FormattedAmount ;
  end;
  {$ENDIF}
  FCurrency := Currency;
end;

procedure TRegistrationFrm.imgLogoClick(Sender: TObject);
begin
  Application.Navigate('https://sideminds.com/',TNavigationTarget.ntPage);
end;

procedure TRegistrationFrm.txtSeniorityChange(Sender: TObject);
var
 Seniority: TSeniorityRolesClassWrapper;
begin
  txtJobTitle.Clear;
  for Seniority in FSeniorityRolesList do
  begin
    if (txtSeniority.Text = Seniority.Seniority) then
    begin
      txtJobTitle.Items.Add(Seniority.JobTitle);
    end;
  end;
end;


procedure TRegistrationFrm.GetCountries(Service: String);
  procedure OnResult(Response: TXDataClientResponse);
  var
    JSONData:   String;
    JsonObject, CountryObj: TJSONObject;
    JSONArray:  TJSONArray;
    I:          Integer;
  begin
    JsonObject := TJSONObject.ParseJSONValue(Response.ResponseText) as TJSONObject;
    JSONArray := JsonObject.GetValue('value') as TJSONArray;
    for I := 0 to JSONArray.Count - 1 do
    begin
      CountryObj := JSONArray.Items[I] as TJSONObject;
      txtCountry.Items.Add(CountryObj.GetValue('CountryName').Value);
      FCountry.Add(StrToInt(CountryObj.GetValue('CountryID').Value), CountryObj.GetValue('CountryName').Value);
      if FCountryCode = CountryObj.GetValue('CountryCode').Value then
      begin
        txtCountry.ItemIndex := I;
      end;
    end;
    CalculateVAT;
  end;

begin
  if (FCountryCode <> '') then
    XDataWebClient.rawInvoke(Service, [FCountryCode], @OnResult)
  else
    //ShowMessage('Country not found, please check connectivity and refresh!')
end;

procedure TRegistrationFrm.GetCompanyPositions(Service: String);
  procedure OnResult(Response: TXDataClientResponse);
  var
    JSONData:   String;
    JsonObject, posObj: TJSONObject;
    JSONArray:  TJSONArray;
    I:          Integer;
    SeniorityRole : TSeniorityRolesClassWrapper;
  begin
    JsonObject := TJSONObject.ParseJSONValue(Response.ResponseText) as TJSONObject;
    JSONArray := JsonObject.GetValue('value') as TJSONArray;
    for I := 0 to JSONArray.Count - 1 do
    begin
      posObj := JSONArray.Items[I] as TJSONObject;
      SeniorityRole := TSeniorityRolesClassWrapper.Create;
      SeniorityRole.SeniorityId := StrToInt(posObj.GetValue('SeniorityId').value);
      SeniorityRole.Seniority   := posObj.GetValue('Seniority').value;
      SeniorityRole.JobTitleId  := StrToInt(posObj.GetValue('JobTitleId').value);
      SeniorityRole.JobTitle    := posObj.GetValue('JobTitle').value;


      FTitles.Add(StrToInt(posObj.GetValue('JobTitleId').value), posObj.GetValue('JobTitle').value);
      txtJobTitle.Items.Add(posObj.GetValue('JobTitle').value);

      if(not FSeniority.ContainsKey(SeniorityRole.SeniorityId)) then
      begin
        FSeniority.Add(StrToInt(posObj.GetValue('SeniorityId').value), posObj.GetValue('Seniority').value);
        txtSeniority.Items.Add(posObj.GetValue('Seniority').value);
      end;
      FSeniorityRolesList.add(SeniorityRole);
    end;
  end;

begin
    XDataWebClient.rawInvoke(Service, [], @OnResult)
end;





// procedure TRegistrationFrm.Toast(Header, Body: String);
// begin
// ToastCount := ToastCount + 1;
// asm
// var toast = document.createElement('div');
// toast.className = 'toast';
// toast.setAttribute('id','toast'+this.ToastCount);
// toast.setAttribute('role','alert');
// toast.setAttribute('aria-live','assertive');
// toast.setAttribute('aria-atomic','true');
// toast.setAttribute('data-bs-delay','15000');
//
// var toasth = document.createElement('div');
// toasth.className = 'toast-header bg-danger text-white';
// toasth.innerHTML = '<strong class="me-auto">'+Header+'</strong>'+
// '<small class="text-light">just now</small>'+
// '<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>';
//
// var toastb = document.createElement('div');
// toastb.className = 'toast-body';
// toastb.innerHTML = Body;
//
// toast.appendChild(toasth);
// toast.appendChild(toastb);
// divToasts.firstElementChild.appendChild(toast);
//
// new bootstrap.Toast(toast).show();
// end;
// end;

// function TRegistrationFrm.JSONRequest(Endpoint: String; Params: array of JSValue): String;
// var
// ClientConn: TXDataWebClient;
// Response: TXDataClientResponse;
// Blob: JSValue;
// Elapsed: TDateTime;
// begin
// Elapsed := Now;
// Result := '';
// LogAction('Request: '+Endpoint);
//
// await(XDataConnect);
// if (XdataConn.Connected) then
// begin
// try
// ClientConn := TXDataWebClient.Create(nil);
// ClientConn.Connection := XDataConn;
// Response := await(ClientConn.RawInvokeAsync(Endpoint, Params));
// Blob := Response.Result;
// asm
// Result = await Blob.text();
// end;
// except on E: Exception do
// begin
// LogAction('Request Exception: '+Endpoint);
// LogAction(' --> ['+E.ClassName+']');
// LogAction(' --> '+E.Message);
// end;
// end;
// end;
//
// LogAction('Response: '+Endpoint+' ('+IntToStr(MillisecondsBetween(Now, Elapsed))+'ms)');
// end;

procedure TRegistrationFrm.LoadDFMValues;
begin
  inherited LoadDFMValues;

  layLeftNavContainer := TPanel.Create(Self);
  WebPanel5 := TPanel.Create(Self);
  WebPanel6 := TPanel.Create(Self);
  lblLogin2 := TLabel.Create(Self);
  imgTesting := THTMLDiv.Create(Self);
  WebPanel7 := TPanel.Create(Self);
  lblTMLCReviews2 := TLabel.Create(Self);
  WebHTMLDiv2 := THTMLDiv.Create(Self);
  WebPanel8 := TPanel.Create(Self);
  lblTMLCAssessment2 := TLabel.Create(Self);
  WebHTMLDiv3 := THTMLDiv.Create(Self);
  WebPanel9 := TPanel.Create(Self);
  lblTheMarketingLeadersCodeBook2 := TLabel.Create(Self);
  WebHTMLDiv4 := THTMLDiv.Create(Self);
  WebPanel10 := TPanel.Create(Self);
  lblOurExperience2 := TLabel.Create(Self);
  WebHTMLDiv5 := THTMLDiv.Create(Self);
  WebPanel11 := TPanel.Create(Self);
  lblOurApproach2 := TLabel.Create(Self);
  WebHTMLDiv6 := THTMLDiv.Create(Self);
  WebPanel12 := TPanel.Create(Self);
  lblContact2 := TLabel.Create(Self);
  WebHTMLDiv7 := THTMLDiv.Create(Self);
  WebPanel13 := TPanel.Create(Self);
  lblBlog2 := TLabel.Create(Self);
  WebHTMLDiv8 := THTMLDiv.Create(Self);
  WebPanel14 := TPanel.Create(Self);
  WebHTMLDiv9 := THTMLDiv.Create(Self);
  TopBar := TPanel.Create(Self);
  imgLogo := TImageControl.Create(Self);
  lblLogin := TLabel.Create(Self);
  lblTheMarketingLeadersCodeBook := TLabel.Create(Self);
  lblTMLCAssessment := TLabel.Create(Self);
  lblTMLCReviews := TLabel.Create(Self);
  lblBlog := TLabel.Create(Self);
  lblContact := TLabel.Create(Self);
  lblOurApproach := TLabel.Create(Self);
  lblOurExperience := TLabel.Create(Self);
  lblWelcomeUser := TLabel.Create('lblWelcomeUser');
  imgProfilePicture := TImageControl.Create(Self);
  btnHamburgerMenu := THTMLDiv.Create(Self);
  btnApply := TButton.Create('btnApply');
  btnSave := TButton.Create('btnSave');
  txtAddress := TEdit.Create('txtAddress');
  txtCity := TComboBox.Create('txtCity');
  txtCompanyName := TEdit.Create('txtCompanyName');
  txtCountry := TComboBox.Create('txtCountry');
  txtEmail := TEdit.Create('txtEmail');
  txtFirstName := TEdit.Create('txtFirstName');
  txtJobTitle := TComboBox.Create('txtJobTitle');
  txtMobile := TEdit.Create('txtMobile');
  txtPassword := TEdit.Create('txtPassword');
  txtPromoCode := TEdit.Create('promoCode');
  txtReTypePassword := TEdit.Create('txtReTypePassword');
  txtSeniority := TComboBox.Create('txtSeniority');
  txtState := TComboBox.Create('txtState');
  txtSurname := TEdit.Create('txtSurname');
  txtZip := TEdit.Create('txtZip');
  layHeaderProfileDropDown := TPanel.Create(Self);
  layNavSignOut := TPanel.Create(Self);
  btnSignOut := TPanel.Create(Self);
  WebLabel10 := TLabel.Create(Self);
  WebHTMLDiv12 := THTMLDiv.Create(Self);
  layNavProfile := TPanel.Create(Self);
  btnGoToDashboard := TPanel.Create(Self);
  WebLabel11 := TLabel.Create(Self);
  WebHTMLDiv13 := THTMLDiv.Create(Self);
  XDataWebClient := TXDataWebClient.Create(Self);
  APIIdentification := TXDataWebConnection.Create(Self);
  WebHttpRequest1 := THttpRequest.Create(Self);
  GetUser := THttpRequest.Create(Self);
  XDataWebClient1 := TXDataWebClient.Create(Self);
  CreateSubscription := THttpRequest.Create(Self);

  layLeftNavContainer.BeforeLoadDFMValues;
  WebPanel5.BeforeLoadDFMValues;
  WebPanel6.BeforeLoadDFMValues;
  lblLogin2.BeforeLoadDFMValues;
  imgTesting.BeforeLoadDFMValues;
  WebPanel7.BeforeLoadDFMValues;
  lblTMLCReviews2.BeforeLoadDFMValues;
  WebHTMLDiv2.BeforeLoadDFMValues;
  WebPanel8.BeforeLoadDFMValues;
  lblTMLCAssessment2.BeforeLoadDFMValues;
  WebHTMLDiv3.BeforeLoadDFMValues;
  WebPanel9.BeforeLoadDFMValues;
  lblTheMarketingLeadersCodeBook2.BeforeLoadDFMValues;
  WebHTMLDiv4.BeforeLoadDFMValues;
  WebPanel10.BeforeLoadDFMValues;
  lblOurExperience2.BeforeLoadDFMValues;
  WebHTMLDiv5.BeforeLoadDFMValues;
  WebPanel11.BeforeLoadDFMValues;
  lblOurApproach2.BeforeLoadDFMValues;
  WebHTMLDiv6.BeforeLoadDFMValues;
  WebPanel12.BeforeLoadDFMValues;
  lblContact2.BeforeLoadDFMValues;
  WebHTMLDiv7.BeforeLoadDFMValues;
  WebPanel13.BeforeLoadDFMValues;
  lblBlog2.BeforeLoadDFMValues;
  WebHTMLDiv8.BeforeLoadDFMValues;
  WebPanel14.BeforeLoadDFMValues;
  WebHTMLDiv9.BeforeLoadDFMValues;
  TopBar.BeforeLoadDFMValues;
  imgLogo.BeforeLoadDFMValues;
  lblLogin.BeforeLoadDFMValues;
  lblTheMarketingLeadersCodeBook.BeforeLoadDFMValues;
  lblTMLCAssessment.BeforeLoadDFMValues;
  lblTMLCReviews.BeforeLoadDFMValues;
  lblBlog.BeforeLoadDFMValues;
  lblContact.BeforeLoadDFMValues;
  lblOurApproach.BeforeLoadDFMValues;
  lblOurExperience.BeforeLoadDFMValues;
  lblWelcomeUser.BeforeLoadDFMValues;
  imgProfilePicture.BeforeLoadDFMValues;
  btnHamburgerMenu.BeforeLoadDFMValues;
  btnApply.BeforeLoadDFMValues;
  btnSave.BeforeLoadDFMValues;
  txtAddress.BeforeLoadDFMValues;
  txtCity.BeforeLoadDFMValues;
  txtCompanyName.BeforeLoadDFMValues;
  txtCountry.BeforeLoadDFMValues;
  txtEmail.BeforeLoadDFMValues;
  txtFirstName.BeforeLoadDFMValues;
  txtJobTitle.BeforeLoadDFMValues;
  txtMobile.BeforeLoadDFMValues;
  txtPassword.BeforeLoadDFMValues;
  txtPromoCode.BeforeLoadDFMValues;
  txtReTypePassword.BeforeLoadDFMValues;
  txtSeniority.BeforeLoadDFMValues;
  txtState.BeforeLoadDFMValues;
  txtSurname.BeforeLoadDFMValues;
  txtZip.BeforeLoadDFMValues;
  layHeaderProfileDropDown.BeforeLoadDFMValues;
  layNavSignOut.BeforeLoadDFMValues;
  btnSignOut.BeforeLoadDFMValues;
  WebLabel10.BeforeLoadDFMValues;
  WebHTMLDiv12.BeforeLoadDFMValues;
  layNavProfile.BeforeLoadDFMValues;
  btnGoToDashboard.BeforeLoadDFMValues;
  WebLabel11.BeforeLoadDFMValues;
  WebHTMLDiv13.BeforeLoadDFMValues;
  XDataWebClient.BeforeLoadDFMValues;
  APIIdentification.BeforeLoadDFMValues;
  WebHttpRequest1.BeforeLoadDFMValues;
  GetUser.BeforeLoadDFMValues;
  XDataWebClient1.BeforeLoadDFMValues;
  CreateSubscription.BeforeLoadDFMValues;
  try
    Name := 'RegistrationFrm';
    Width := 1800;
    Height := 879;
    CSSLibrary := cssBootstrap;
    ElementFont := efCSS;
    Font.Charset := DEFAULT_CHARSET;
    Font.Color := clWindowText;
    Font.Height := -15;
    Font.Name := 'Tahoma';
    Font.Style := [];
    ParentFont := False;
    SetEvent(Self, 'OnCreate', 'WebFormCreate');
    SetEvent(Self, 'OnDestroy', 'WebFormDestroy');
    SetEvent(Self, 'OnResize', 'WebFormResize');
    SetEvent(Self, 'OnShow', 'WebFormShow');
    layLeftNavContainer.SetParentComponent(Self);
    layLeftNavContainer.Name := 'layLeftNavContainer';
    layLeftNavContainer.Left := 0;
    layLeftNavContainer.Top := 94;
    layLeftNavContainer.Width := 1800;
    layLeftNavContainer.Height := 785;
    layLeftNavContainer.Cursor := crHandPoint;
    layLeftNavContainer.ElementClassName := 'card';
    layLeftNavContainer.Align := alClient;
    layLeftNavContainer.BorderStyle := bsNone;
    layLeftNavContainer.ChildOrder := 2;
    layLeftNavContainer.Color := clWhite;
    layLeftNavContainer.ElementBodyClassName := 'card-body';
    layLeftNavContainer.ElementFont := efCSS;
    layLeftNavContainer.ShowCaption := False;
    layLeftNavContainer.TabOrder := 0;
    layLeftNavContainer.Visible := False;
    SetEvent(layLeftNavContainer, Self, 'OnClick', 'CloseMobileNav');
    WebPanel5.SetParentComponent(layLeftNavContainer);
    WebPanel5.Name := 'WebPanel5';
    WebPanel5.Left := 0;
    WebPanel5.Top := 0;
    WebPanel5.Width := 433;
    WebPanel5.Height := 785;
    WebPanel5.Margins.Left := 0;
    WebPanel5.Margins.Top := 0;
    WebPanel5.Margins.Right := 0;
    WebPanel5.Margins.Bottom := 0;
    WebPanel5.ElementClassName := 'card';
    WebPanel5.Align := alLeft;
    WebPanel5.BorderStyle := bsNone;
    WebPanel5.Color := clWhite;
    WebPanel5.ElementBodyClassName := 'card-body';
    WebPanel5.ElementFont := efCSS;
    WebPanel5.ShowCaption := False;
    WebPanel5.TabOrder := 0;
    WebPanel6.SetParentComponent(WebPanel5);
    WebPanel6.Name := 'WebPanel6';
    WebPanel6.Left := 0;
    WebPanel6.Top := 480;
    WebPanel6.Width := 433;
    WebPanel6.Height := 60;
    WebPanel6.ElementClassName := 'card';
    WebPanel6.Align := alTop;
    WebPanel6.BorderStyle := bsNone;
    WebPanel6.Color := clWhite;
    WebPanel6.ElementBodyClassName := 'card-body';
    WebPanel6.ElementFont := efCSS;
    WebPanel6.TabOrder := 0;
    lblLogin2.SetParentComponent(WebPanel6);
    lblLogin2.Name := 'lblLogin2';
    lblLogin2.AlignWithMargins := True;
    lblLogin2.Left := 62;
    lblLogin2.Top := 0;
    lblLogin2.Width := 371;
    lblLogin2.Height := 60;
    lblLogin2.Cursor := crHandPoint;
    lblLogin2.Margins.Left := 0;
    lblLogin2.Margins.Top := 0;
    lblLogin2.Margins.Right := 0;
    lblLogin2.Margins.Bottom := 0;
    lblLogin2.Align := alClient;
    lblLogin2.Caption := 'Login';
    lblLogin2.ElementFont := efCSS;
    lblLogin2.Font.Charset := ANSI_CHARSET;
    lblLogin2.Font.Color := clBlack;
    lblLogin2.Font.Height := -20;
    lblLogin2.Font.Name := 'Segoe UI';
    lblLogin2.Font.Style := [];
    lblLogin2.HeightStyle := ssPercent;
    lblLogin2.HeightPercent := 100.000000000000000000;
    lblLogin2.HTMLType := tSPAN;
    lblLogin2.Layout := tlCenter;
    lblLogin2.ParentFont := False;
    lblLogin2.WidthStyle := ssAuto;
    lblLogin2.WidthPercent := 100.000000000000000000;
    SetEvent(lblLogin2, Self, 'OnClick', 'lblLoginClick');
    SetEvent(lblLogin2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblLogin2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    imgTesting.SetParentComponent(WebPanel6);
    imgTesting.Name := 'imgTesting';
    imgTesting.AlignWithMargins := True;
    imgTesting.Left := 10;
    imgTesting.Top := 10;
    imgTesting.Width := 42;
    imgTesting.Height := 40;
    imgTesting.Margins.Left := 10;
    imgTesting.Margins.Top := 10;
    imgTesting.Margins.Right := 10;
    imgTesting.Margins.Bottom := 10;
    imgTesting.WidthStyle := ssAuto;
    imgTesting.Align := alLeft;
    imgTesting.ChildOrder := 2;
    imgTesting.ElementFont := efCSS;
    imgTesting.HTML.BeginUpdate;
    try
      imgTesting.HTML.Clear;
      imgTesting.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-person-fill-lock" viewBox="0 0 16 16">');
      imgTesting.HTML.Add('  <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0m-9 8c0 1 1 1 1 1h5v-1a2 2 0 0 1 .01-.2 4.49 4.49 0 0 1 1.534-3.693Q8.844 9.002 8 9c-5 0-6 3-6 4m7 0a1 1 0 0 ');
      imgTesting.HTML.Add('1 1-1v-1a2 2 0 1 1 4 0v1a1 1 0 0 1 1 1v2a1 1 0 0 1-1 1h-4a1 1 0 0 1-1-1zm3-3a1 1 0 0 0-1 1v1h2v-1a1 1 0 0 0-1-1"/>');
      imgTesting.HTML.Add('</svg>');
    finally
      imgTesting.HTML.EndUpdate;
    end;
    imgTesting.Role := '';
    WebPanel7.SetParentComponent(WebPanel5);
    WebPanel7.Name := 'WebPanel7';
    WebPanel7.Left := 0;
    WebPanel7.Top := 300;
    WebPanel7.Width := 433;
    WebPanel7.Height := 60;
    WebPanel7.ElementClassName := 'card';
    WebPanel7.Align := alTop;
    WebPanel7.BorderStyle := bsNone;
    WebPanel7.ChildOrder := 1;
    WebPanel7.Color := clWhite;
    WebPanel7.ElementBodyClassName := 'card-body';
    WebPanel7.ElementFont := efCSS;
    WebPanel7.TabOrder := 1;
    lblTMLCReviews2.SetParentComponent(WebPanel7);
    lblTMLCReviews2.Name := 'lblTMLCReviews2';
    lblTMLCReviews2.AlignWithMargins := True;
    lblTMLCReviews2.Left := 62;
    lblTMLCReviews2.Top := 0;
    lblTMLCReviews2.Width := 371;
    lblTMLCReviews2.Height := 60;
    lblTMLCReviews2.Cursor := crHandPoint;
    lblTMLCReviews2.Margins.Left := 0;
    lblTMLCReviews2.Margins.Top := 0;
    lblTMLCReviews2.Margins.Right := 0;
    lblTMLCReviews2.Margins.Bottom := 0;
    lblTMLCReviews2.Align := alClient;
    lblTMLCReviews2.Caption := 'TMLC Reviews';
    lblTMLCReviews2.ElementFont := efCSS;
    lblTMLCReviews2.Font.Charset := ANSI_CHARSET;
    lblTMLCReviews2.Font.Color := clBlack;
    lblTMLCReviews2.Font.Height := -21;
    lblTMLCReviews2.Font.Name := 'Segoe UI';
    lblTMLCReviews2.Font.Style := [];
    lblTMLCReviews2.HeightStyle := ssPercent;
    lblTMLCReviews2.HeightPercent := 100.000000000000000000;
    lblTMLCReviews2.HTMLType := tSPAN;
    lblTMLCReviews2.Layout := tlCenter;
    lblTMLCReviews2.ParentFont := False;
    lblTMLCReviews2.WidthStyle := ssAuto;
    lblTMLCReviews2.WidthPercent := 100.000000000000000000;
    SetEvent(lblTMLCReviews2, Self, 'OnClick', 'lblTMLCReviewsClick');
    SetEvent(lblTMLCReviews2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTMLCReviews2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv2.SetParentComponent(WebPanel7);
    WebHTMLDiv2.Name := 'WebHTMLDiv2';
    WebHTMLDiv2.AlignWithMargins := True;
    WebHTMLDiv2.Left := 10;
    WebHTMLDiv2.Top := 10;
    WebHTMLDiv2.Width := 42;
    WebHTMLDiv2.Height := 40;
    WebHTMLDiv2.Margins.Left := 10;
    WebHTMLDiv2.Margins.Top := 10;
    WebHTMLDiv2.Margins.Right := 10;
    WebHTMLDiv2.Margins.Bottom := 10;
    WebHTMLDiv2.WidthStyle := ssAuto;
    WebHTMLDiv2.Align := alLeft;
    WebHTMLDiv2.ChildOrder := 2;
    WebHTMLDiv2.ElementFont := efCSS;
    WebHTMLDiv2.HTML.BeginUpdate;
    try
      WebHTMLDiv2.HTML.Clear;
      WebHTMLDiv2.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">');
      WebHTMLDiv2.HTML.Add('  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 ');
      WebHTMLDiv2.HTML.Add('2H.5a.5.5 0 0 1-.5-.5M3.102 4l1.313 7h8.17l1.313-7zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4m7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4m-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2m7 ');
      WebHTMLDiv2.HTML.Add('0a1 1 0 1 1 0 2 1 1 0 0 1 0-2"/>');
      WebHTMLDiv2.HTML.Add('</svg>');
    finally
      WebHTMLDiv2.HTML.EndUpdate;
    end;
    WebHTMLDiv2.Role := '';
    WebPanel8.SetParentComponent(WebPanel5);
    WebPanel8.Name := 'WebPanel8';
    WebPanel8.Left := 0;
    WebPanel8.Top := 240;
    WebPanel8.Width := 433;
    WebPanel8.Height := 60;
    WebPanel8.ElementClassName := 'card';
    WebPanel8.Align := alTop;
    WebPanel8.BorderStyle := bsNone;
    WebPanel8.ChildOrder := 2;
    WebPanel8.Color := clWhite;
    WebPanel8.ElementBodyClassName := 'card-body';
    WebPanel8.ElementFont := efCSS;
    WebPanel8.TabOrder := 2;
    lblTMLCAssessment2.SetParentComponent(WebPanel8);
    lblTMLCAssessment2.Name := 'lblTMLCAssessment2';
    lblTMLCAssessment2.AlignWithMargins := True;
    lblTMLCAssessment2.Left := 62;
    lblTMLCAssessment2.Top := 0;
    lblTMLCAssessment2.Width := 371;
    lblTMLCAssessment2.Height := 60;
    lblTMLCAssessment2.Cursor := crHandPoint;
    lblTMLCAssessment2.Margins.Left := 0;
    lblTMLCAssessment2.Margins.Top := 0;
    lblTMLCAssessment2.Margins.Right := 0;
    lblTMLCAssessment2.Margins.Bottom := 0;
    lblTMLCAssessment2.Align := alClient;
    lblTMLCAssessment2.Caption := 'TMLC Assessment';
    lblTMLCAssessment2.ElementFont := efCSS;
    lblTMLCAssessment2.Font.Charset := ANSI_CHARSET;
    lblTMLCAssessment2.Font.Color := clBlack;
    lblTMLCAssessment2.Font.Height := -21;
    lblTMLCAssessment2.Font.Name := 'Segoe UI';
    lblTMLCAssessment2.Font.Style := [];
    lblTMLCAssessment2.HeightStyle := ssPercent;
    lblTMLCAssessment2.HeightPercent := 100.000000000000000000;
    lblTMLCAssessment2.HTMLType := tSPAN;
    lblTMLCAssessment2.Layout := tlCenter;
    lblTMLCAssessment2.ParentFont := False;
    lblTMLCAssessment2.WidthStyle := ssAuto;
    lblTMLCAssessment2.WidthPercent := 100.000000000000000000;
    SetEvent(lblTMLCAssessment2, Self, 'OnClick', 'lblTMLCAssessmentClick');
    SetEvent(lblTMLCAssessment2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTMLCAssessment2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv3.SetParentComponent(WebPanel8);
    WebHTMLDiv3.Name := 'WebHTMLDiv3';
    WebHTMLDiv3.AlignWithMargins := True;
    WebHTMLDiv3.Left := 10;
    WebHTMLDiv3.Top := 10;
    WebHTMLDiv3.Width := 42;
    WebHTMLDiv3.Height := 40;
    WebHTMLDiv3.Margins.Left := 10;
    WebHTMLDiv3.Margins.Top := 10;
    WebHTMLDiv3.Margins.Right := 10;
    WebHTMLDiv3.Margins.Bottom := 10;
    WebHTMLDiv3.WidthStyle := ssAuto;
    WebHTMLDiv3.Align := alLeft;
    WebHTMLDiv3.ChildOrder := 2;
    WebHTMLDiv3.ElementFont := efCSS;
    WebHTMLDiv3.HTML.BeginUpdate;
    try
      WebHTMLDiv3.HTML.Clear;
      WebHTMLDiv3.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-chat-left-dots" viewBox="0 0 16 16">');
      WebHTMLDiv3.HTML.Add('  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"/>');
      WebHTMLDiv3.HTML.Add('  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>');
      WebHTMLDiv3.HTML.Add('</svg>');
    finally
      WebHTMLDiv3.HTML.EndUpdate;
    end;
    WebHTMLDiv3.Role := '';
    WebPanel9.SetParentComponent(WebPanel5);
    WebPanel9.Name := 'WebPanel9';
    WebPanel9.Left := 0;
    WebPanel9.Top := 180;
    WebPanel9.Width := 433;
    WebPanel9.Height := 60;
    WebPanel9.ElementClassName := 'card';
    WebPanel9.Align := alTop;
    WebPanel9.BorderStyle := bsNone;
    WebPanel9.ChildOrder := 3;
    WebPanel9.Color := clWhite;
    WebPanel9.ElementBodyClassName := 'card-body';
    WebPanel9.ElementFont := efCSS;
    WebPanel9.TabOrder := 3;
    lblTheMarketingLeadersCodeBook2.SetParentComponent(WebPanel9);
    lblTheMarketingLeadersCodeBook2.Name := 'lblTheMarketingLeadersCodeBook2';
    lblTheMarketingLeadersCodeBook2.AlignWithMargins := True;
    lblTheMarketingLeadersCodeBook2.Left := 62;
    lblTheMarketingLeadersCodeBook2.Top := 0;
    lblTheMarketingLeadersCodeBook2.Width := 371;
    lblTheMarketingLeadersCodeBook2.Height := 60;
    lblTheMarketingLeadersCodeBook2.Cursor := crHandPoint;
    lblTheMarketingLeadersCodeBook2.Margins.Left := 0;
    lblTheMarketingLeadersCodeBook2.Margins.Top := 0;
    lblTheMarketingLeadersCodeBook2.Margins.Right := 0;
    lblTheMarketingLeadersCodeBook2.Margins.Bottom := 0;
    lblTheMarketingLeadersCodeBook2.Align := alClient;
    lblTheMarketingLeadersCodeBook2.Caption := 'The Marketing Leader'#39's Code Book';
    lblTheMarketingLeadersCodeBook2.ElementFont := efCSS;
    lblTheMarketingLeadersCodeBook2.Font.Charset := ANSI_CHARSET;
    lblTheMarketingLeadersCodeBook2.Font.Color := clBlack;
    lblTheMarketingLeadersCodeBook2.Font.Height := -21;
    lblTheMarketingLeadersCodeBook2.Font.Name := 'Segoe UI';
    lblTheMarketingLeadersCodeBook2.Font.Style := [];
    lblTheMarketingLeadersCodeBook2.HeightStyle := ssPercent;
    lblTheMarketingLeadersCodeBook2.HeightPercent := 100.000000000000000000;
    lblTheMarketingLeadersCodeBook2.HTMLType := tSPAN;
    lblTheMarketingLeadersCodeBook2.Layout := tlCenter;
    lblTheMarketingLeadersCodeBook2.ParentFont := False;
    lblTheMarketingLeadersCodeBook2.WidthStyle := ssAuto;
    lblTheMarketingLeadersCodeBook2.WidthPercent := 100.000000000000000000;
    SetEvent(lblTheMarketingLeadersCodeBook2, Self, 'OnClick', 'lblTheMarketingLeadersCodeBookClick');
    SetEvent(lblTheMarketingLeadersCodeBook2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTheMarketingLeadersCodeBook2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv4.SetParentComponent(WebPanel9);
    WebHTMLDiv4.Name := 'WebHTMLDiv4';
    WebHTMLDiv4.AlignWithMargins := True;
    WebHTMLDiv4.Left := 10;
    WebHTMLDiv4.Top := 10;
    WebHTMLDiv4.Width := 42;
    WebHTMLDiv4.Height := 40;
    WebHTMLDiv4.Margins.Left := 10;
    WebHTMLDiv4.Margins.Top := 10;
    WebHTMLDiv4.Margins.Right := 10;
    WebHTMLDiv4.Margins.Bottom := 10;
    WebHTMLDiv4.WidthStyle := ssAuto;
    WebHTMLDiv4.Align := alLeft;
    WebHTMLDiv4.ChildOrder := 2;
    WebHTMLDiv4.ElementFont := efCSS;
    WebHTMLDiv4.HTML.BeginUpdate;
    try
      WebHTMLDiv4.HTML.Clear;
      WebHTMLDiv4.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-newspaper" viewBox="0 0 16 16">');
      WebHTMLDiv4.HTML.Add('  <path d="M0 2.5A1.5 1.5 0 0 1 1.5 1h11A1.5 1.5 0 0 1 14 2.5v10.528c0 .3-.05.654-.238.972h.738a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 1 1 0v9a1.5 1.5 0 0 1-1.5 ');
      WebHTMLDiv4.HTML.Add('1.5H1.497A1.497 1.497 0 0 1 0 13.5v-11zM12 14c.37 0 .654-.211.853-.441.092-.106.147-.279.147-.531V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5v11c0 ');
      WebHTMLDiv4.HTML.Add('.278.223.5.497.5H12z"/>');
      WebHTMLDiv4.HTML.Add('  <path d="M2 3h10v2H2V3zm0 3h4v3H2V6zm0 4h4v1H2v-1zm0 2h4v1H2v-1zm5-6h2v1H7V6zm3 0h2v1h-2V6zM7 8h2v1H7V8zm3 0h2v1h-2V8zm-3 ');
      WebHTMLDiv4.HTML.Add('2h2v1H7v-1zm3 0h2v1h-2v-1zm-3 2h2v1H7v-1zm3 0h2v1h-2v-1z"/>');
      WebHTMLDiv4.HTML.Add('</svg>');
    finally
      WebHTMLDiv4.HTML.EndUpdate;
    end;
    WebHTMLDiv4.Role := '';
    WebPanel10.SetParentComponent(WebPanel5);
    WebPanel10.Name := 'WebPanel10';
    WebPanel10.Left := 0;
    WebPanel10.Top := 120;
    WebPanel10.Width := 433;
    WebPanel10.Height := 60;
    WebPanel10.ElementClassName := 'card';
    WebPanel10.Align := alTop;
    WebPanel10.BorderStyle := bsNone;
    WebPanel10.ChildOrder := 4;
    WebPanel10.Color := clWhite;
    WebPanel10.ElementBodyClassName := 'card-body';
    WebPanel10.ElementFont := efCSS;
    WebPanel10.TabOrder := 4;
    lblOurExperience2.SetParentComponent(WebPanel10);
    lblOurExperience2.Name := 'lblOurExperience2';
    lblOurExperience2.AlignWithMargins := True;
    lblOurExperience2.Left := 62;
    lblOurExperience2.Top := 0;
    lblOurExperience2.Width := 371;
    lblOurExperience2.Height := 60;
    lblOurExperience2.Cursor := crHandPoint;
    lblOurExperience2.Margins.Left := 0;
    lblOurExperience2.Margins.Top := 0;
    lblOurExperience2.Margins.Right := 0;
    lblOurExperience2.Margins.Bottom := 0;
    lblOurExperience2.Align := alClient;
    lblOurExperience2.Caption := 'Our Experience';
    lblOurExperience2.ElementFont := efCSS;
    lblOurExperience2.Font.Charset := ANSI_CHARSET;
    lblOurExperience2.Font.Color := clBlack;
    lblOurExperience2.Font.Height := -21;
    lblOurExperience2.Font.Name := 'Segoe UI';
    lblOurExperience2.Font.Style := [];
    lblOurExperience2.HeightStyle := ssPercent;
    lblOurExperience2.HeightPercent := 100.000000000000000000;
    lblOurExperience2.HTMLType := tSPAN;
    lblOurExperience2.Layout := tlCenter;
    lblOurExperience2.ParentFont := False;
    lblOurExperience2.WidthStyle := ssAuto;
    lblOurExperience2.WidthPercent := 100.000000000000000000;
    SetEvent(lblOurExperience2, Self, 'OnClick', 'lblOurExperienceClick');
    SetEvent(lblOurExperience2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblOurExperience2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv5.SetParentComponent(WebPanel10);
    WebHTMLDiv5.Name := 'WebHTMLDiv5';
    WebHTMLDiv5.AlignWithMargins := True;
    WebHTMLDiv5.Left := 10;
    WebHTMLDiv5.Top := 10;
    WebHTMLDiv5.Width := 42;
    WebHTMLDiv5.Height := 40;
    WebHTMLDiv5.Margins.Left := 10;
    WebHTMLDiv5.Margins.Top := 10;
    WebHTMLDiv5.Margins.Right := 10;
    WebHTMLDiv5.Margins.Bottom := 10;
    WebHTMLDiv5.WidthStyle := ssAuto;
    WebHTMLDiv5.Align := alLeft;
    WebHTMLDiv5.ChildOrder := 2;
    WebHTMLDiv5.ElementFont := efCSS;
    WebHTMLDiv5.HTML.BeginUpdate;
    try
      WebHTMLDiv5.HTML.Clear;
      WebHTMLDiv5.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-info-circle" viewBox="0 0 16 16">');
      WebHTMLDiv5.HTML.Add('  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>');
      WebHTMLDiv5.HTML.Add('  <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088');
      WebHTMLDiv5.HTML.Add('-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>');
      WebHTMLDiv5.HTML.Add('</svg>');
    finally
      WebHTMLDiv5.HTML.EndUpdate;
    end;
    WebHTMLDiv5.Role := '';
    WebPanel11.SetParentComponent(WebPanel5);
    WebPanel11.Name := 'WebPanel11';
    WebPanel11.Left := 0;
    WebPanel11.Top := 60;
    WebPanel11.Width := 433;
    WebPanel11.Height := 60;
    WebPanel11.ElementClassName := 'card';
    WebPanel11.Align := alTop;
    WebPanel11.BorderStyle := bsNone;
    WebPanel11.ChildOrder := 5;
    WebPanel11.Color := clWhite;
    WebPanel11.ElementBodyClassName := 'card-body';
    WebPanel11.ElementFont := efCSS;
    WebPanel11.TabOrder := 5;
    lblOurApproach2.SetParentComponent(WebPanel11);
    lblOurApproach2.Name := 'lblOurApproach2';
    lblOurApproach2.AlignWithMargins := True;
    lblOurApproach2.Left := 62;
    lblOurApproach2.Top := 0;
    lblOurApproach2.Width := 371;
    lblOurApproach2.Height := 60;
    lblOurApproach2.Cursor := crHandPoint;
    lblOurApproach2.Margins.Left := 0;
    lblOurApproach2.Margins.Top := 0;
    lblOurApproach2.Margins.Right := 0;
    lblOurApproach2.Margins.Bottom := 0;
    lblOurApproach2.Align := alClient;
    lblOurApproach2.Caption := 'Our Services';
    lblOurApproach2.ElementFont := efCSS;
    lblOurApproach2.Font.Charset := ANSI_CHARSET;
    lblOurApproach2.Font.Color := clBlack;
    lblOurApproach2.Font.Height := -21;
    lblOurApproach2.Font.Name := 'Segoe UI';
    lblOurApproach2.Font.Style := [];
    lblOurApproach2.HeightStyle := ssPercent;
    lblOurApproach2.HeightPercent := 100.000000000000000000;
    lblOurApproach2.HTMLType := tSPAN;
    lblOurApproach2.Layout := tlCenter;
    lblOurApproach2.ParentFont := False;
    lblOurApproach2.WidthStyle := ssAuto;
    lblOurApproach2.WidthPercent := 100.000000000000000000;
    SetEvent(lblOurApproach2, Self, 'OnClick', 'lblOurApproachClick');
    SetEvent(lblOurApproach2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblOurApproach2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv6.SetParentComponent(WebPanel11);
    WebHTMLDiv6.Name := 'WebHTMLDiv6';
    WebHTMLDiv6.AlignWithMargins := True;
    WebHTMLDiv6.Left := 10;
    WebHTMLDiv6.Top := 10;
    WebHTMLDiv6.Width := 42;
    WebHTMLDiv6.Height := 40;
    WebHTMLDiv6.Margins.Left := 10;
    WebHTMLDiv6.Margins.Top := 10;
    WebHTMLDiv6.Margins.Right := 10;
    WebHTMLDiv6.Margins.Bottom := 10;
    WebHTMLDiv6.WidthStyle := ssAuto;
    WebHTMLDiv6.Align := alLeft;
    WebHTMLDiv6.ChildOrder := 2;
    WebHTMLDiv6.ElementFont := efCSS;
    WebHTMLDiv6.HTML.BeginUpdate;
    try
      WebHTMLDiv6.HTML.Clear;
      WebHTMLDiv6.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-house" viewBox="0 0 16 16">');
      WebHTMLDiv6.HTML.Add('  <path d="M8.707 1.5a1 1 0 0 0-1.414 0L.646 8.146a.5.5 0 0 0 .708.708L2 8.207V13.5A1.5 1.5 0 0 0 3.5 15h9a1.5 1.5 0 0 0 1.5-1.5V8.207l.646.647a.5.5 0 0 0 ');
      WebHTMLDiv6.HTML.Add('.708-.708L13 5.793V2.5a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1.293zM13 7.207V13.5a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5V7.207l5-5z"/>');
      WebHTMLDiv6.HTML.Add('</svg>');
    finally
      WebHTMLDiv6.HTML.EndUpdate;
    end;
    WebHTMLDiv6.Role := '';
    WebPanel12.SetParentComponent(WebPanel5);
    WebPanel12.Name := 'WebPanel12';
    WebPanel12.Left := 0;
    WebPanel12.Top := 420;
    WebPanel12.Width := 433;
    WebPanel12.Height := 60;
    WebPanel12.ElementClassName := 'card';
    WebPanel12.Align := alTop;
    WebPanel12.BorderStyle := bsNone;
    WebPanel12.ChildOrder := 6;
    WebPanel12.Color := clWhite;
    WebPanel12.ElementBodyClassName := 'card-body';
    WebPanel12.ElementFont := efCSS;
    WebPanel12.TabOrder := 6;
    lblContact2.SetParentComponent(WebPanel12);
    lblContact2.Name := 'lblContact2';
    lblContact2.AlignWithMargins := True;
    lblContact2.Left := 62;
    lblContact2.Top := 0;
    lblContact2.Width := 371;
    lblContact2.Height := 60;
    lblContact2.Cursor := crHandPoint;
    lblContact2.Margins.Left := 0;
    lblContact2.Margins.Top := 0;
    lblContact2.Margins.Right := 0;
    lblContact2.Margins.Bottom := 0;
    lblContact2.Align := alClient;
    lblContact2.Caption := 'Contact';
    lblContact2.ElementFont := efCSS;
    lblContact2.Font.Charset := ANSI_CHARSET;
    lblContact2.Font.Color := clBlack;
    lblContact2.Font.Height := -21;
    lblContact2.Font.Name := 'Segoe UI';
    lblContact2.Font.Style := [];
    lblContact2.HeightStyle := ssPercent;
    lblContact2.HeightPercent := 100.000000000000000000;
    lblContact2.HTMLType := tSPAN;
    lblContact2.Layout := tlCenter;
    lblContact2.ParentFont := False;
    lblContact2.WidthStyle := ssAuto;
    lblContact2.WidthPercent := 100.000000000000000000;
    SetEvent(lblContact2, Self, 'OnClick', 'lblContactClick');
    SetEvent(lblContact2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblContact2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv7.SetParentComponent(WebPanel12);
    WebHTMLDiv7.Name := 'WebHTMLDiv7';
    WebHTMLDiv7.AlignWithMargins := True;
    WebHTMLDiv7.Left := 10;
    WebHTMLDiv7.Top := 10;
    WebHTMLDiv7.Width := 42;
    WebHTMLDiv7.Height := 40;
    WebHTMLDiv7.Margins.Left := 10;
    WebHTMLDiv7.Margins.Top := 10;
    WebHTMLDiv7.Margins.Right := 10;
    WebHTMLDiv7.Margins.Bottom := 10;
    WebHTMLDiv7.WidthStyle := ssAuto;
    WebHTMLDiv7.Align := alLeft;
    WebHTMLDiv7.ChildOrder := 2;
    WebHTMLDiv7.ElementFont := efCSS;
    WebHTMLDiv7.HTML.BeginUpdate;
    try
      WebHTMLDiv7.HTML.Clear;
      WebHTMLDiv7.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-person-lines-fill" viewBox="0 0 16 16">');
      WebHTMLDiv7.HTML.Add('  <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5m.5 2.5a.5.5 ');
      WebHTMLDiv7.HTML.Add('0 0 0 0 1h4a.5.5 0 0 0 0-1zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1z"/>');
      WebHTMLDiv7.HTML.Add('</svg>');
    finally
      WebHTMLDiv7.HTML.EndUpdate;
    end;
    WebHTMLDiv7.Role := '';
    WebPanel13.SetParentComponent(WebPanel5);
    WebPanel13.Name := 'WebPanel13';
    WebPanel13.Left := 0;
    WebPanel13.Top := 360;
    WebPanel13.Width := 433;
    WebPanel13.Height := 60;
    WebPanel13.ElementClassName := 'card';
    WebPanel13.Align := alTop;
    WebPanel13.BorderStyle := bsNone;
    WebPanel13.ChildOrder := 7;
    WebPanel13.Color := clWhite;
    WebPanel13.ElementBodyClassName := 'card-body';
    WebPanel13.ElementFont := efCSS;
    WebPanel13.TabOrder := 7;
    lblBlog2.SetParentComponent(WebPanel13);
    lblBlog2.Name := 'lblBlog2';
    lblBlog2.AlignWithMargins := True;
    lblBlog2.Left := 62;
    lblBlog2.Top := 0;
    lblBlog2.Width := 371;
    lblBlog2.Height := 60;
    lblBlog2.Cursor := crHandPoint;
    lblBlog2.Margins.Left := 0;
    lblBlog2.Margins.Top := 0;
    lblBlog2.Margins.Right := 0;
    lblBlog2.Margins.Bottom := 0;
    lblBlog2.Align := alClient;
    lblBlog2.Caption := 'Blog';
    lblBlog2.ElementFont := efCSS;
    lblBlog2.Font.Charset := ANSI_CHARSET;
    lblBlog2.Font.Color := clBlack;
    lblBlog2.Font.Height := -21;
    lblBlog2.Font.Name := 'Segoe UI';
    lblBlog2.Font.Style := [];
    lblBlog2.HeightStyle := ssPercent;
    lblBlog2.HeightPercent := 100.000000000000000000;
    lblBlog2.HTMLType := tSPAN;
    lblBlog2.Layout := tlCenter;
    lblBlog2.ParentFont := False;
    lblBlog2.WidthStyle := ssAuto;
    lblBlog2.WidthPercent := 100.000000000000000000;
    SetEvent(lblBlog2, Self, 'OnClick', 'lblBlogClick');
    SetEvent(lblBlog2, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblBlog2, Self, 'OnMouseEnter', 'LinkMouseEnter');
    WebHTMLDiv8.SetParentComponent(WebPanel13);
    WebHTMLDiv8.Name := 'WebHTMLDiv8';
    WebHTMLDiv8.AlignWithMargins := True;
    WebHTMLDiv8.Left := 10;
    WebHTMLDiv8.Top := 10;
    WebHTMLDiv8.Width := 42;
    WebHTMLDiv8.Height := 40;
    WebHTMLDiv8.Margins.Left := 10;
    WebHTMLDiv8.Margins.Top := 10;
    WebHTMLDiv8.Margins.Right := 10;
    WebHTMLDiv8.Margins.Bottom := 10;
    WebHTMLDiv8.WidthStyle := ssAuto;
    WebHTMLDiv8.Align := alLeft;
    WebHTMLDiv8.ChildOrder := 2;
    WebHTMLDiv8.ElementFont := efCSS;
    WebHTMLDiv8.HTML.BeginUpdate;
    try
      WebHTMLDiv8.HTML.Clear;
      WebHTMLDiv8.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" width="90%" height="90%" fill="currentColor" class="bi bi-card-heading" viewBox="0 0 16 16">');
      WebHTMLDiv8.HTML.Add('  <path d="M14.5 3a.5.5 0 0 1 .5.5v9a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5zm-13-1A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 ');
      WebHTMLDiv8.HTML.Add('0 1.5-1.5v-9A1.5 1.5 0 0 0 14.5 2z"/>');
      WebHTMLDiv8.HTML.Add('  <path d="M3 8.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5m0-5a.5.5 0 0 1 .5-.5h9a.5.5 0 ');
      WebHTMLDiv8.HTML.Add('0 1 .5.5v1a.5.5 0 0 1-.5.5h-9a.5.5 0 0 1-.5-.5z"/>');
      WebHTMLDiv8.HTML.Add('</svg>');
    finally
      WebHTMLDiv8.HTML.EndUpdate;
    end;
    WebHTMLDiv8.Role := '';
    WebPanel14.SetParentComponent(WebPanel5);
    WebPanel14.Name := 'WebPanel14';
    WebPanel14.Left := 0;
    WebPanel14.Top := 0;
    WebPanel14.Width := 433;
    WebPanel14.Height := 60;
    WebPanel14.ElementClassName := 'card';
    WebPanel14.Align := alTop;
    WebPanel14.BorderStyle := bsNone;
    WebPanel14.ChildOrder := 5;
    WebPanel14.Color := clWhite;
    WebPanel14.ElementBodyClassName := 'card-body';
    WebPanel14.ElementFont := efCSS;
    WebPanel14.TabOrder := 8;
    WebHTMLDiv9.SetParentComponent(WebPanel14);
    WebHTMLDiv9.Name := 'WebHTMLDiv9';
    WebHTMLDiv9.AlignWithMargins := True;
    WebHTMLDiv9.Left := 389;
    WebHTMLDiv9.Top := 0;
    WebHTMLDiv9.Width := 41;
    WebHTMLDiv9.Height := 60;
    WebHTMLDiv9.Cursor := crHandPoint;
    WebHTMLDiv9.Margins.Top := 0;
    WebHTMLDiv9.Margins.Bottom := 0;
    WebHTMLDiv9.WidthStyle := ssAuto;
    WebHTMLDiv9.Align := alRight;
    WebHTMLDiv9.ChildOrder := 2;
    WebHTMLDiv9.ElementFont := efCSS;
    WebHTMLDiv9.HTML.BeginUpdate;
    try
      WebHTMLDiv9.HTML.Clear;
      WebHTMLDiv9.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - ');
      WebHTMLDiv9.HTML.Add('https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M256 48a208 208 0 1 1 0 416 208 ');
      WebHTMLDiv9.HTML.Add('208 0 1 1 0-416zm0 464A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM175 175c-9.4 9.4-9.4 24.6 0 33.9l47 47-47 47c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 ');
      WebHTMLDiv9.HTML.Add('0l47-47 47 47c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-47-47 47-47c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-47 47-47-47c-9.4-9.4-24.6-9.4-33.9 ');
      WebHTMLDiv9.HTML.Add('0z"/></svg>');
    finally
      WebHTMLDiv9.HTML.EndUpdate;
    end;
    WebHTMLDiv9.Role := '';
    SetEvent(WebHTMLDiv9, Self, 'OnClick', 'CloseMobileNav');
    TopBar.SetParentComponent(Self);
    TopBar.Name := 'TopBar';
    TopBar.Left := 0;
    TopBar.Top := 0;
    TopBar.Width := 1800;
    TopBar.Height := 94;
    TopBar.ElementClassName := 'card';
    TopBar.Align := alTop;
    TopBar.BorderStyle := bsNone;
    TopBar.ChildOrder := 18;
    TopBar.Color := clWhite;
    TopBar.ElementBodyClassName := 'h-100 text-black';
    TopBar.ElementFont := efCSS;
    TopBar.Font.Charset := DEFAULT_CHARSET;
    TopBar.Font.Color := clWhite;
    TopBar.Font.Height := -21;
    TopBar.Font.Name := 'Segoe UI';
    TopBar.Font.Style := [];
    TopBar.Padding.Left := 43;
    TopBar.Padding.Top := 6;
    TopBar.Padding.Right := 9;
    TopBar.Padding.Bottom := 6;
    TopBar.ParentFont := False;
    TopBar.ShowCaption := False;
    TopBar.TabOrder := 1;
    imgLogo.SetParentComponent(TopBar);
    imgLogo.Name := 'imgLogo';
    imgLogo.Left := 43;
    imgLogo.Top := 6;
    imgLogo.Width := 86;
    imgLogo.Height := 82;
    imgLogo.Cursor := crHandPoint;
    imgLogo.Margins.Left := 57;
    imgLogo.Margins.Top := 0;
    imgLogo.Margins.Right := 23;
    imgLogo.Margins.Bottom := 0;
    imgLogo.WidthStyle := ssAuto;
    imgLogo.HeightPercent := 100.000000000000000000;
    imgLogo.WidthPercent := 100.000000000000000000;
    imgLogo.Align := alLeft;
    SetEvent(imgLogo, Self, 'OnClick', 'imgLogoClick');
    imgLogo.URL := 'https://sideminds.com/img/logo.png';
    lblLogin.SetParentComponent(TopBar);
    lblLogin.Name := 'lblLogin';
    lblLogin.AlignWithMargins := True;
    lblLogin.Left := 1386;
    lblLogin.Top := 6;
    lblLogin.Width := 49;
    lblLogin.Height := 82;
    lblLogin.Cursor := crHandPoint;
    lblLogin.Margins.Left := 0;
    lblLogin.Margins.Top := 0;
    lblLogin.Margins.Right := 46;
    lblLogin.Margins.Bottom := 0;
    lblLogin.Align := alRight;
    lblLogin.Caption := 'Login';
    lblLogin.ElementFont := efCSS;
    lblLogin.Font.Charset := ANSI_CHARSET;
    lblLogin.Font.Color := clBlack;
    lblLogin.Font.Height := -20;
    lblLogin.Font.Name := 'Segoe UI';
    lblLogin.Font.Style := [];
    lblLogin.HeightStyle := ssPercent;
    lblLogin.HeightPercent := 100.000000000000000000;
    lblLogin.HTMLType := tSPAN;
    lblLogin.Layout := tlCenter;
    lblLogin.ParentFont := False;
    lblLogin.WidthStyle := ssAuto;
    lblLogin.WidthPercent := 100.000000000000000000;
    SetEvent(lblLogin, Self, 'OnClick', 'lblLoginClick');
    SetEvent(lblLogin, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblLogin, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblTheMarketingLeadersCodeBook.SetParentComponent(TopBar);
    lblTheMarketingLeadersCodeBook.Name := 'lblTheMarketingLeadersCodeBook';
    lblTheMarketingLeadersCodeBook.AlignWithMargins := True;
    lblTheMarketingLeadersCodeBook.Left := 654;
    lblTheMarketingLeadersCodeBook.Top := 6;
    lblTheMarketingLeadersCodeBook.Width := 322;
    lblTheMarketingLeadersCodeBook.Height := 82;
    lblTheMarketingLeadersCodeBook.Cursor := crHandPoint;
    lblTheMarketingLeadersCodeBook.Margins.Left := 0;
    lblTheMarketingLeadersCodeBook.Margins.Top := 0;
    lblTheMarketingLeadersCodeBook.Margins.Right := 23;
    lblTheMarketingLeadersCodeBook.Margins.Bottom := 0;
    lblTheMarketingLeadersCodeBook.Align := alRight;
    lblTheMarketingLeadersCodeBook.Caption := 'The Marketing Leader'#39's Code Book';
    lblTheMarketingLeadersCodeBook.ElementFont := efCSS;
    lblTheMarketingLeadersCodeBook.Font.Charset := ANSI_CHARSET;
    lblTheMarketingLeadersCodeBook.Font.Color := clBlack;
    lblTheMarketingLeadersCodeBook.Font.Height := -21;
    lblTheMarketingLeadersCodeBook.Font.Name := 'Segoe UI';
    lblTheMarketingLeadersCodeBook.Font.Style := [];
    lblTheMarketingLeadersCodeBook.HeightStyle := ssPercent;
    lblTheMarketingLeadersCodeBook.HeightPercent := 100.000000000000000000;
    lblTheMarketingLeadersCodeBook.HTMLType := tSPAN;
    lblTheMarketingLeadersCodeBook.Layout := tlCenter;
    lblTheMarketingLeadersCodeBook.ParentFont := False;
    lblTheMarketingLeadersCodeBook.WidthStyle := ssAuto;
    lblTheMarketingLeadersCodeBook.WidthPercent := 100.000000000000000000;
    SetEvent(lblTheMarketingLeadersCodeBook, Self, 'OnClick', 'lblTheMarketingLeadersCodeBookClick');
    SetEvent(lblTheMarketingLeadersCodeBook, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTheMarketingLeadersCodeBook, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblTMLCAssessment.SetParentComponent(TopBar);
    lblTMLCAssessment.Name := 'lblTMLCAssessment';
    lblTMLCAssessment.AlignWithMargins := True;
    lblTMLCAssessment.Left := 999;
    lblTMLCAssessment.Top := 6;
    lblTMLCAssessment.Width := 109;
    lblTMLCAssessment.Height := 82;
    lblTMLCAssessment.Cursor := crHandPoint;
    lblTMLCAssessment.Margins.Left := 0;
    lblTMLCAssessment.Margins.Top := 0;
    lblTMLCAssessment.Margins.Right := 23;
    lblTMLCAssessment.Margins.Bottom := 0;
    lblTMLCAssessment.Align := alRight;
    lblTMLCAssessment.Caption := 'Assessment';
    lblTMLCAssessment.ElementFont := efCSS;
    lblTMLCAssessment.Font.Charset := ANSI_CHARSET;
    lblTMLCAssessment.Font.Color := clBlack;
    lblTMLCAssessment.Font.Height := -21;
    lblTMLCAssessment.Font.Name := 'Segoe UI';
    lblTMLCAssessment.Font.Style := [];
    lblTMLCAssessment.HeightStyle := ssPercent;
    lblTMLCAssessment.HeightPercent := 100.000000000000000000;
    lblTMLCAssessment.HTMLType := tSPAN;
    lblTMLCAssessment.Layout := tlCenter;
    lblTMLCAssessment.ParentFont := False;
    lblTMLCAssessment.WidthStyle := ssAuto;
    lblTMLCAssessment.WidthPercent := 100.000000000000000000;
    SetEvent(lblTMLCAssessment, Self, 'OnClick', 'lblTMLCAssessmentClick');
    SetEvent(lblTMLCAssessment, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTMLCAssessment, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblTMLCReviews.SetParentComponent(TopBar);
    lblTMLCReviews.Name := 'lblTMLCReviews';
    lblTMLCReviews.AlignWithMargins := True;
    lblTMLCReviews.Left := 1131;
    lblTMLCReviews.Top := 6;
    lblTMLCReviews.Width := 73;
    lblTMLCReviews.Height := 82;
    lblTMLCReviews.Cursor := crHandPoint;
    lblTMLCReviews.Margins.Left := 0;
    lblTMLCReviews.Margins.Top := 0;
    lblTMLCReviews.Margins.Right := 23;
    lblTMLCReviews.Margins.Bottom := 0;
    lblTMLCReviews.Align := alRight;
    lblTMLCReviews.Caption := 'Reviews';
    lblTMLCReviews.ElementFont := efCSS;
    lblTMLCReviews.Font.Charset := ANSI_CHARSET;
    lblTMLCReviews.Font.Color := clBlack;
    lblTMLCReviews.Font.Height := -21;
    lblTMLCReviews.Font.Name := 'Segoe UI';
    lblTMLCReviews.Font.Style := [];
    lblTMLCReviews.HeightStyle := ssPercent;
    lblTMLCReviews.HeightPercent := 100.000000000000000000;
    lblTMLCReviews.HTMLType := tSPAN;
    lblTMLCReviews.Layout := tlCenter;
    lblTMLCReviews.ParentFont := False;
    lblTMLCReviews.WidthStyle := ssAuto;
    lblTMLCReviews.WidthPercent := 100.000000000000000000;
    SetEvent(lblTMLCReviews, Self, 'OnClick', 'lblTMLCReviewsClick');
    SetEvent(lblTMLCReviews, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblTMLCReviews, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblBlog.SetParentComponent(TopBar);
    lblBlog.Name := 'lblBlog';
    lblBlog.AlignWithMargins := True;
    lblBlog.Left := 1227;
    lblBlog.Top := 6;
    lblBlog.Width := 41;
    lblBlog.Height := 82;
    lblBlog.Cursor := crHandPoint;
    lblBlog.Margins.Left := 0;
    lblBlog.Margins.Top := 0;
    lblBlog.Margins.Right := 23;
    lblBlog.Margins.Bottom := 0;
    lblBlog.Align := alRight;
    lblBlog.Caption := 'Blog';
    lblBlog.ElementFont := efCSS;
    lblBlog.Font.Charset := ANSI_CHARSET;
    lblBlog.Font.Color := clBlack;
    lblBlog.Font.Height := -21;
    lblBlog.Font.Name := 'Segoe UI';
    lblBlog.Font.Style := [];
    lblBlog.HeightStyle := ssPercent;
    lblBlog.HeightPercent := 100.000000000000000000;
    lblBlog.HTMLType := tSPAN;
    lblBlog.Layout := tlCenter;
    lblBlog.ParentFont := False;
    lblBlog.WidthStyle := ssAuto;
    lblBlog.WidthPercent := 100.000000000000000000;
    SetEvent(lblBlog, Self, 'OnClick', 'lblBlogClick');
    SetEvent(lblBlog, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblBlog, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblContact.SetParentComponent(TopBar);
    lblContact.Name := 'lblContact';
    lblContact.AlignWithMargins := True;
    lblContact.Left := 1291;
    lblContact.Top := 6;
    lblContact.Width := 72;
    lblContact.Height := 82;
    lblContact.Cursor := crHandPoint;
    lblContact.Margins.Left := 0;
    lblContact.Margins.Top := 0;
    lblContact.Margins.Right := 23;
    lblContact.Margins.Bottom := 0;
    lblContact.Align := alRight;
    lblContact.Caption := 'Contact';
    lblContact.ElementFont := efCSS;
    lblContact.Font.Charset := ANSI_CHARSET;
    lblContact.Font.Color := clBlack;
    lblContact.Font.Height := -21;
    lblContact.Font.Name := 'Segoe UI';
    lblContact.Font.Style := [];
    lblContact.HeightStyle := ssPercent;
    lblContact.HeightPercent := 100.000000000000000000;
    lblContact.HTMLType := tSPAN;
    lblContact.Layout := tlCenter;
    lblContact.ParentFont := False;
    lblContact.WidthStyle := ssAuto;
    lblContact.WidthPercent := 100.000000000000000000;
    SetEvent(lblContact, Self, 'OnClick', 'lblContactClick');
    SetEvent(lblContact, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblContact, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblOurApproach.SetParentComponent(TopBar);
    lblOurApproach.Name := 'lblOurApproach';
    lblOurApproach.AlignWithMargins := True;
    lblOurApproach.Left := 352;
    lblOurApproach.Top := 6;
    lblOurApproach.Width := 115;
    lblOurApproach.Height := 82;
    lblOurApproach.Cursor := crHandPoint;
    lblOurApproach.Margins.Left := 0;
    lblOurApproach.Margins.Top := 0;
    lblOurApproach.Margins.Right := 23;
    lblOurApproach.Margins.Bottom := 0;
    lblOurApproach.Align := alRight;
    lblOurApproach.Caption := 'Our Services';
    lblOurApproach.ElementFont := efCSS;
    lblOurApproach.Font.Charset := ANSI_CHARSET;
    lblOurApproach.Font.Color := clBlack;
    lblOurApproach.Font.Height := -21;
    lblOurApproach.Font.Name := 'Segoe UI';
    lblOurApproach.Font.Style := [];
    lblOurApproach.HeightStyle := ssPercent;
    lblOurApproach.HeightPercent := 100.000000000000000000;
    lblOurApproach.HTMLType := tSPAN;
    lblOurApproach.Layout := tlCenter;
    lblOurApproach.ParentFont := False;
    lblOurApproach.WidthStyle := ssAuto;
    lblOurApproach.WidthPercent := 100.000000000000000000;
    SetEvent(lblOurApproach, Self, 'OnClick', 'lblOurApproachClick');
    SetEvent(lblOurApproach, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblOurApproach, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblOurExperience.SetParentComponent(TopBar);
    lblOurExperience.Name := 'lblOurExperience';
    lblOurExperience.AlignWithMargins := True;
    lblOurExperience.Left := 490;
    lblOurExperience.Top := 6;
    lblOurExperience.Width := 141;
    lblOurExperience.Height := 82;
    lblOurExperience.Cursor := crHandPoint;
    lblOurExperience.Margins.Left := 0;
    lblOurExperience.Margins.Top := 0;
    lblOurExperience.Margins.Right := 23;
    lblOurExperience.Margins.Bottom := 0;
    lblOurExperience.Align := alRight;
    lblOurExperience.Caption := 'Our Experience';
    lblOurExperience.ElementFont := efCSS;
    lblOurExperience.Font.Charset := ANSI_CHARSET;
    lblOurExperience.Font.Color := clBlack;
    lblOurExperience.Font.Height := -21;
    lblOurExperience.Font.Name := 'Segoe UI';
    lblOurExperience.Font.Style := [];
    lblOurExperience.HeightStyle := ssPercent;
    lblOurExperience.HeightPercent := 100.000000000000000000;
    lblOurExperience.HTMLType := tSPAN;
    lblOurExperience.Layout := tlCenter;
    lblOurExperience.ParentFont := False;
    lblOurExperience.WidthStyle := ssAuto;
    lblOurExperience.WidthPercent := 100.000000000000000000;
    SetEvent(lblOurExperience, Self, 'OnClick', 'lblOurExperienceClick');
    SetEvent(lblOurExperience, Self, 'OnMouseLeave', 'LinkMouseLeave');
    SetEvent(lblOurExperience, Self, 'OnMouseEnter', 'LinkMouseEnter');
    lblWelcomeUser.SetParentComponent(TopBar);
    lblWelcomeUser.Name := 'lblWelcomeUser';
    lblWelcomeUser.AlignWithMargins := True;
    lblWelcomeUser.Left := 1549;
    lblWelcomeUser.Top := 6;
    lblWelcomeUser.Width := 134;
    lblWelcomeUser.Height := 82;
    lblWelcomeUser.Cursor := crHandPoint;
    lblWelcomeUser.Margins.Left := 0;
    lblWelcomeUser.Margins.Top := 0;
    lblWelcomeUser.Margins.Right := 26;
    lblWelcomeUser.Margins.Bottom := 0;
    lblWelcomeUser.Align := alRight;
    lblWelcomeUser.Caption := 'Welcome Jane';
    lblWelcomeUser.ElementFont := efCSS;
    lblWelcomeUser.Font.Charset := ANSI_CHARSET;
    lblWelcomeUser.Font.Color := clBlack;
    lblWelcomeUser.Font.Height := -21;
    lblWelcomeUser.Font.Name := 'Segoe UI';
    lblWelcomeUser.Font.Style := [];
    lblWelcomeUser.HeightStyle := ssPercent;
    lblWelcomeUser.HeightPercent := 100.000000000000000000;
    lblWelcomeUser.HTMLType := tSPAN;
    lblWelcomeUser.Layout := tlCenter;
    lblWelcomeUser.ParentFont := False;
    lblWelcomeUser.Visible := False;
    lblWelcomeUser.WidthStyle := ssAuto;
    lblWelcomeUser.WidthPercent := 100.000000000000000000;
    SetEvent(lblWelcomeUser, Self, 'OnClick', 'OpenHeaderProfileDropDown');
    imgProfilePicture.SetParentComponent(TopBar);
    imgProfilePicture.Name := 'imgProfilePicture';
    imgProfilePicture.AlignWithMargins := True;
    imgProfilePicture.Left := 1490;
    imgProfilePicture.Top := 27;
    imgProfilePicture.Width := 48;
    imgProfilePicture.Height := 44;
    imgProfilePicture.Cursor := crHandPoint;
    imgProfilePicture.Margins.Left := 9;
    imgProfilePicture.Margins.Top := 21;
    imgProfilePicture.Margins.Right := 11;
    imgProfilePicture.Margins.Bottom := 17;
    imgProfilePicture.ElementClassName := 'rounded-circle';
    imgProfilePicture.HeightPercent := 100.000000000000000000;
    imgProfilePicture.WidthPercent := 100.000000000000000000;
    imgProfilePicture.Align := alRight;
    imgProfilePicture.ChildOrder := 3;
    imgProfilePicture.Visible := False;
    SetEvent(imgProfilePicture, Self, 'OnClick', 'OpenHeaderProfileDropDown');
    imgProfilePicture.Picture.LoadFromFile('uRegistration.TopBar.imgProfilePicture.Picture.jpg');
    btnHamburgerMenu.SetParentComponent(TopBar);
    btnHamburgerMenu.Name := 'btnHamburgerMenu';
    btnHamburgerMenu.AlignWithMargins := True;
    btnHamburgerMenu.Left := 1709;
    btnHamburgerMenu.Top := 26;
    btnHamburgerMenu.Width := 42;
    btnHamburgerMenu.Height := 42;
    btnHamburgerMenu.Cursor := crHandPoint;
    btnHamburgerMenu.Margins.Left := 0;
    btnHamburgerMenu.Margins.Top := 20;
    btnHamburgerMenu.Margins.Right := 40;
    btnHamburgerMenu.Margins.Bottom := 20;
    btnHamburgerMenu.WidthStyle := ssAuto;
    btnHamburgerMenu.Align := alRight;
    btnHamburgerMenu.ChildOrder := 2;
    btnHamburgerMenu.ElementFont := efCSS;
    btnHamburgerMenu.HTML.BeginUpdate;
    try
      btnHamburgerMenu.HTML.Clear;
      btnHamburgerMenu.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" height="100%" width="100%" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.0 by @fontawesome - ');
      btnHamburgerMenu.HTML.Add('https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--><path d="M0 96C0 78.3 14.3 64 32 ');
      btnHamburgerMenu.HTML.Add('64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 ');
      btnHamburgerMenu.HTML.Add('0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z"/></svg>');
    finally
      btnHamburgerMenu.HTML.EndUpdate;
    end;
    btnHamburgerMenu.Role := '';
    SetEvent(btnHamburgerMenu, Self, 'OnClick', 'btnHamburgerMenuClick');
    btnApply.SetParentComponent(Self);
    btnApply.Name := 'btnApply';
    btnApply.Left := 786;
    btnApply.Top := 228;
    btnApply.Width := 96;
    btnApply.Height := 25;
    btnApply.Caption := 'Apply';
    btnApply.Color := clWhite;
    btnApply.ChildOrder := 6;
    btnApply.ElementFont := efCSS;
    btnApply.Font.Charset := DEFAULT_CHARSET;
    btnApply.Font.Color := clWhite;
    btnApply.Font.Height := -15;
    btnApply.Font.Name := 'Tahoma';
    btnApply.Font.Style := [];
    btnApply.HeightStyle := ssPercent;
    btnApply.HeightPercent := 100.000000000000000000;
    btnApply.ParentFont := False;
    btnApply.WidthStyle := ssPercent;
    btnApply.WidthPercent := 100.000000000000000000;
    SetEvent(btnApply, Self, 'OnClick', 'btnApplyClick');
    btnSave.SetParentComponent(Self);
    btnSave.Name := 'btnSave';
    btnSave.Left := 1084;
    btnSave.Top := 619;
    btnSave.Width := 121;
    btnSave.Height := 25;
    btnSave.Caption := 'Register';
    btnSave.ChildOrder := 20;
    btnSave.ElementFont := efCSS;
    btnSave.ElementPosition := epIgnore;
    btnSave.HeightStyle := ssAuto;
    btnSave.HeightPercent := 100.000000000000000000;
    btnSave.WidthStyle := ssAuto;
    btnSave.WidthPercent := 100.000000000000000000;
    SetEvent(btnSave, Self, 'OnClick', 'btnSaveClick');
    txtAddress.SetParentComponent(Self);
    txtAddress.Name := 'txtAddress';
    txtAddress.Left := 917;
    txtAddress.Top := 386;
    txtAddress.Width := 288;
    txtAddress.Height := 25;
    txtAddress.ChildOrder := 13;
    txtAddress.ElementFont := efCSS;
    txtAddress.ElementPosition := epIgnore;
    txtAddress.HeightStyle := ssAuto;
    txtAddress.HeightPercent := 100.000000000000000000;
    txtAddress.WidthStyle := ssAuto;
    txtAddress.WidthPercent := 100.000000000000000000;
    txtCity.SetParentComponent(Self);
    txtCity.Name := 'txtCity';
    txtCity.Left := 917;
    txtCity.Top := 426;
    txtCity.Width := 124;
    txtCity.Height := 26;
    txtCity.ElementFont := efCSS;
    txtCity.ElementPosition := epIgnore;
    txtCity.HeightStyle := ssAuto;
    txtCity.HeightPercent := 100.000000000000000000;
    txtCity.WidthStyle := ssAuto;
    txtCity.WidthPercent := 100.000000000000000000;
    txtCity.ItemIndex := -1;
    txtCompanyName.SetParentComponent(Self);
    txtCompanyName.Name := 'txtCompanyName';
    txtCompanyName.Left := 917;
    txtCompanyName.Top := 284;
    txtCompanyName.Width := 288;
    txtCompanyName.Height := 25;
    txtCompanyName.ChildOrder := 8;
    txtCompanyName.ElementFont := efCSS;
    txtCompanyName.ElementPosition := epIgnore;
    txtCompanyName.HeightStyle := ssAuto;
    txtCompanyName.HeightPercent := 100.000000000000000000;
    txtCompanyName.WidthStyle := ssAuto;
    txtCompanyName.WidthPercent := 100.000000000000000000;
    txtCountry.SetParentComponent(Self);
    txtCountry.Name := 'txtCountry';
    txtCountry.Left := 917;
    txtCountry.Top := 476;
    txtCountry.Width := 124;
    txtCountry.Height := 26;
    txtCountry.ElementFont := efCSS;
    txtCountry.ElementPosition := epIgnore;
    txtCountry.HeightStyle := ssAuto;
    txtCountry.HeightPercent := 100.000000000000000000;
    txtCountry.WidthStyle := ssAuto;
    txtCountry.WidthPercent := 100.000000000000000000;
    txtCountry.ItemIndex := -1;
    txtEmail.SetParentComponent(Self);
    txtEmail.Name := 'txtEmail';
    txtEmail.Left := 917;
    txtEmail.Top := 570;
    txtEmail.Width := 288;
    txtEmail.Height := 25;
    txtEmail.ChildOrder := 11;
    txtEmail.ElementFont := efCSS;
    txtEmail.ElementPosition := epIgnore;
    txtEmail.HeightStyle := ssAuto;
    txtEmail.HeightPercent := 100.000000000000000000;
    txtEmail.WidthStyle := ssAuto;
    txtEmail.WidthPercent := 100.000000000000000000;
    txtFirstName.SetParentComponent(Self);
    txtFirstName.Name := 'txtFirstName';
    txtFirstName.Left := 917;
    txtFirstName.Top := 227;
    txtFirstName.Width := 124;
    txtFirstName.Height := 25;
    txtFirstName.ChildOrder := 1;
    txtFirstName.ElementFont := efCSS;
    txtFirstName.ElementPosition := epIgnore;
    txtFirstName.HeightStyle := ssAuto;
    txtFirstName.HeightPercent := 100.000000000000000000;
    txtFirstName.WidthStyle := ssAuto;
    txtFirstName.WidthPercent := 100.000000000000000000;
    txtJobTitle.SetParentComponent(Self);
    txtJobTitle.Name := 'txtJobTitle';
    txtJobTitle.Left := 1084;
    txtJobTitle.Top := 332;
    txtJobTitle.Width := 121;
    txtJobTitle.Height := 26;
    txtJobTitle.ElementFont := efCSS;
    txtJobTitle.ElementPosition := epIgnore;
    txtJobTitle.HeightStyle := ssAuto;
    txtJobTitle.HeightPercent := 100.000000000000000000;
    txtJobTitle.WidthStyle := ssAuto;
    txtJobTitle.WidthPercent := 100.000000000000000000;
    txtJobTitle.ItemIndex := -1;
    txtMobile.SetParentComponent(Self);
    txtMobile.Name := 'txtMobile';
    txtMobile.Left := 917;
    txtMobile.Top := 618;
    txtMobile.Width := 124;
    txtMobile.Height := 25;
    txtMobile.ChildOrder := 17;
    txtMobile.ElementFont := efCSS;
    txtMobile.ElementPosition := epIgnore;
    txtMobile.HeightStyle := ssAuto;
    txtMobile.HeightPercent := 100.000000000000000000;
    txtMobile.WidthStyle := ssAuto;
    txtMobile.WidthPercent := 100.000000000000000000;
    txtPassword.SetParentComponent(Self);
    txtPassword.Name := 'txtPassword';
    txtPassword.Left := 917;
    txtPassword.Top := 524;
    txtPassword.Width := 124;
    txtPassword.Height := 25;
    txtPassword.ChildOrder := 3;
    txtPassword.ElementFont := efCSS;
    txtPassword.ElementPosition := epIgnore;
    txtPassword.HeightStyle := ssAuto;
    txtPassword.HeightPercent := 100.000000000000000000;
    txtPassword.WidthStyle := ssAuto;
    txtPassword.WidthPercent := 100.000000000000000000;
    txtPromoCode.SetParentComponent(Self);
    txtPromoCode.Name := 'txtPromoCode';
    txtPromoCode.Left := 605;
    txtPromoCode.Top := 227;
    txtPromoCode.Width := 148;
    txtPromoCode.Height := 25;
    txtPromoCode.ChildOrder := 9;
    txtPromoCode.ElementFont := efCSS;
    txtPromoCode.ElementPosition := epIgnore;
    txtPromoCode.HeightStyle := ssAuto;
    txtPromoCode.HeightPercent := 100.000000000000000000;
    txtPromoCode.WidthStyle := ssAuto;
    txtPromoCode.WidthPercent := 100.000000000000000000;
    txtReTypePassword.SetParentComponent(Self);
    txtReTypePassword.Name := 'txtReTypePassword';
    txtReTypePassword.Left := 1084;
    txtReTypePassword.Top := 524;
    txtReTypePassword.Width := 121;
    txtReTypePassword.Height := 25;
    txtReTypePassword.ChildOrder := 16;
    txtReTypePassword.ElementFont := efCSS;
    txtReTypePassword.ElementPosition := epIgnore;
    txtReTypePassword.HeightStyle := ssAuto;
    txtReTypePassword.HeightPercent := 100.000000000000000000;
    txtReTypePassword.WidthStyle := ssAuto;
    txtReTypePassword.WidthPercent := 100.000000000000000000;
    txtSeniority.SetParentComponent(Self);
    txtSeniority.Name := 'txtSeniority';
    txtSeniority.Left := 917;
    txtSeniority.Top := 332;
    txtSeniority.Width := 124;
    txtSeniority.Height := 26;
    txtSeniority.ElementFont := efCSS;
    txtSeniority.ElementPosition := epIgnore;
    txtSeniority.HeightStyle := ssAuto;
    txtSeniority.HeightPercent := 100.000000000000000000;
    txtSeniority.WidthStyle := ssAuto;
    txtSeniority.WidthPercent := 100.000000000000000000;
    SetEvent(txtSeniority, Self, 'OnChange', 'txtSeniorityChange');
    txtSeniority.ItemIndex := -1;
    txtState.SetParentComponent(Self);
    txtState.Name := 'txtState';
    txtState.Left := 1084;
    txtState.Top := 426;
    txtState.Width := 121;
    txtState.Height := 26;
    txtState.ElementFont := efCSS;
    txtState.ElementPosition := epIgnore;
    txtState.HeightStyle := ssAuto;
    txtState.HeightPercent := 100.000000000000000000;
    txtState.WidthStyle := ssAuto;
    txtState.WidthPercent := 100.000000000000000000;
    txtState.ItemIndex := -1;
    txtSurname.SetParentComponent(Self);
    txtSurname.Name := 'txtSurname';
    txtSurname.Left := 1084;
    txtSurname.Top := 227;
    txtSurname.Width := 121;
    txtSurname.Height := 25;
    txtSurname.ChildOrder := 4;
    txtSurname.ElementFont := efCSS;
    txtSurname.ElementPosition := epIgnore;
    txtSurname.HeightStyle := ssAuto;
    txtSurname.HeightPercent := 100.000000000000000000;
    txtSurname.WidthStyle := ssAuto;
    txtSurname.WidthPercent := 100.000000000000000000;
    txtZip.SetParentComponent(Self);
    txtZip.Name := 'txtZip';
    txtZip.Left := 1084;
    txtZip.Top := 476;
    txtZip.Width := 121;
    txtZip.Height := 25;
    txtZip.ChildOrder := 10;
    txtZip.ElementFont := efCSS;
    txtZip.ElementPosition := epIgnore;
    txtZip.HeightStyle := ssAuto;
    txtZip.HeightPercent := 100.000000000000000000;
    txtZip.WidthStyle := ssAuto;
    txtZip.WidthPercent := 100.000000000000000000;
    layHeaderProfileDropDown.SetParentComponent(Self);
    layHeaderProfileDropDown.Name := 'layHeaderProfileDropDown';
    layHeaderProfileDropDown.Left := 1455;
    layHeaderProfileDropDown.Top := 98;
    layHeaderProfileDropDown.Width := 337;
    layHeaderProfileDropDown.Height := 100;
    layHeaderProfileDropDown.ElementClassName := 'card';
    layHeaderProfileDropDown.Anchors := [akTop,akRight];
    layHeaderProfileDropDown.ChildOrder := 1;
    layHeaderProfileDropDown.Color := clWhite;
    layHeaderProfileDropDown.ElementBodyClassName := 'card-body';
    layHeaderProfileDropDown.ElementFont := efCSS;
    layHeaderProfileDropDown.TabOrder := 19;
    layHeaderProfileDropDown.Visible := False;
    layNavSignOut.SetParentComponent(layHeaderProfileDropDown);
    layNavSignOut.Name := 'layNavSignOut';
    layNavSignOut.Left := 0;
    layNavSignOut.Top := 50;
    layNavSignOut.Width := 337;
    layNavSignOut.Height := 50;
    layNavSignOut.ElementClassName := 'card';
    layNavSignOut.Align := alTop;
    layNavSignOut.BorderStyle := bsNone;
    layNavSignOut.ChildOrder := 6;
    layNavSignOut.Color := clWhite;
    layNavSignOut.ElementBodyClassName := 'card-body';
    layNavSignOut.ElementFont := efCSS;
    layNavSignOut.Font.Charset := DEFAULT_CHARSET;
    layNavSignOut.Font.Color := clBlack;
    layNavSignOut.Font.Height := -21;
    layNavSignOut.Font.Name := 'Segoe UI';
    layNavSignOut.Font.Style := [];
    layNavSignOut.ParentFont := False;
    layNavSignOut.ShowCaption := False;
    layNavSignOut.TabOrder := 0;
    btnSignOut.SetParentComponent(layNavSignOut);
    btnSignOut.Name := 'btnSignOut';
    btnSignOut.AlignWithMargins := True;
    btnSignOut.Left := 5;
    btnSignOut.Top := 5;
    btnSignOut.Width := 327;
    btnSignOut.Height := 40;
    btnSignOut.Cursor := crHandPoint;
    btnSignOut.Margins.Left := 5;
    btnSignOut.Margins.Top := 5;
    btnSignOut.Margins.Right := 5;
    btnSignOut.Margins.Bottom := 5;
    btnSignOut.ElementClassName := 'card';
    btnSignOut.Align := alClient;
    btnSignOut.BorderStyle := bsNone;
    btnSignOut.Color := clWhite;
    btnSignOut.ElementBodyClassName := 'card-body';
    btnSignOut.ElementFont := efCSS;
    btnSignOut.Font.Charset := DEFAULT_CHARSET;
    btnSignOut.Font.Color := clBlack;
    btnSignOut.Font.Height := -21;
    btnSignOut.Font.Name := 'Segoe UI';
    btnSignOut.Font.Style := [];
    btnSignOut.ParentFont := False;
    btnSignOut.ShowCaption := False;
    btnSignOut.TabOrder := 0;
    SetEvent(btnSignOut, Self, 'OnClick', 'btnSignOutClick');
    SetEvent(btnSignOut, Self, 'OnMouseEnter', 'btnDashboardMouseEnter');
    SetEvent(btnSignOut, Self, 'OnMouseLeave', 'btnDashboardMouseLeave');
    WebLabel10.SetParentComponent(btnSignOut);
    WebLabel10.Name := 'WebLabel10';
    WebLabel10.AlignWithMargins := True;
    WebLabel10.Left := 49;
    WebLabel10.Top := 3;
    WebLabel10.Width := 278;
    WebLabel10.Height := 37;
    WebLabel10.Margins.Right := 0;
    WebLabel10.Margins.Bottom := 0;
    WebLabel10.Align := alClient;
    WebLabel10.Caption := 'Sign Out';
    WebLabel10.ElementFont := efCSS;
    WebLabel10.Font.Charset := DEFAULT_CHARSET;
    WebLabel10.Font.Color := clBlack;
    WebLabel10.Font.Height := -21;
    WebLabel10.Font.Name := 'Segoe UI';
    WebLabel10.Font.Style := [];
    WebLabel10.HeightStyle := ssAuto;
    WebLabel10.HeightPercent := 100.000000000000000000;
    WebLabel10.HTMLType := tSPAN;
    WebLabel10.Layout := tlCenter;
    WebLabel10.ParentFont := False;
    WebLabel10.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel10, Self, 'OnClick', 'btnSignOutClick');
    WebHTMLDiv12.SetParentComponent(btnSignOut);
    WebHTMLDiv12.Name := 'WebHTMLDiv12';
    WebHTMLDiv12.AlignWithMargins := True;
    WebHTMLDiv12.Left := 3;
    WebHTMLDiv12.Top := 0;
    WebHTMLDiv12.Width := 40;
    WebHTMLDiv12.Height := 40;
    WebHTMLDiv12.Margins.Top := 0;
    WebHTMLDiv12.Margins.Bottom := 0;
    WebHTMLDiv12.WidthStyle := ssAuto;
    WebHTMLDiv12.Align := alLeft;
    WebHTMLDiv12.ChildOrder := 2;
    WebHTMLDiv12.ElementFont := efCSS;
    WebHTMLDiv12.HTML.BeginUpdate;
    try
      WebHTMLDiv12.HTML.Clear;
      WebHTMLDiv12.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 512 512"><path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 ');
      WebHTMLDiv12.HTML.Add('27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 ');
      WebHTMLDiv12.HTML.Add('0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32');
      WebHTMLDiv12.HTML.Add('-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"/></svg>');
    finally
      WebHTMLDiv12.HTML.EndUpdate;
    end;
    WebHTMLDiv12.Role := '';
    SetEvent(WebHTMLDiv12, Self, 'OnClick', 'btnSignOutClick');
    layNavProfile.SetParentComponent(layHeaderProfileDropDown);
    layNavProfile.Name := 'layNavProfile';
    layNavProfile.Left := 0;
    layNavProfile.Top := 0;
    layNavProfile.Width := 337;
    layNavProfile.Height := 50;
    layNavProfile.ElementClassName := 'card';
    layNavProfile.Align := alTop;
    layNavProfile.BorderStyle := bsNone;
    layNavProfile.ChildOrder := 6;
    layNavProfile.Color := clWhite;
    layNavProfile.ElementBodyClassName := 'card-body';
    layNavProfile.ElementFont := efCSS;
    layNavProfile.Font.Charset := DEFAULT_CHARSET;
    layNavProfile.Font.Color := clBlack;
    layNavProfile.Font.Height := -21;
    layNavProfile.Font.Name := 'Segoe UI';
    layNavProfile.Font.Style := [];
    layNavProfile.ParentFont := False;
    layNavProfile.ShowCaption := False;
    layNavProfile.TabOrder := 1;
    btnGoToDashboard.SetParentComponent(layNavProfile);
    btnGoToDashboard.Name := 'btnGoToDashboard';
    btnGoToDashboard.AlignWithMargins := True;
    btnGoToDashboard.Left := 5;
    btnGoToDashboard.Top := 5;
    btnGoToDashboard.Width := 327;
    btnGoToDashboard.Height := 40;
    btnGoToDashboard.Cursor := crHandPoint;
    btnGoToDashboard.Margins.Left := 5;
    btnGoToDashboard.Margins.Top := 5;
    btnGoToDashboard.Margins.Right := 5;
    btnGoToDashboard.Margins.Bottom := 5;
    btnGoToDashboard.ElementClassName := 'card';
    btnGoToDashboard.Align := alClient;
    btnGoToDashboard.BorderStyle := bsNone;
    btnGoToDashboard.Color := clWhite;
    btnGoToDashboard.ElementBodyClassName := 'card-body';
    btnGoToDashboard.ElementFont := efCSS;
    btnGoToDashboard.Font.Charset := DEFAULT_CHARSET;
    btnGoToDashboard.Font.Color := clBlack;
    btnGoToDashboard.Font.Height := -21;
    btnGoToDashboard.Font.Name := 'Segoe UI';
    btnGoToDashboard.Font.Style := [];
    btnGoToDashboard.ParentFont := False;
    btnGoToDashboard.ShowCaption := False;
    btnGoToDashboard.TabOrder := 0;
    SetEvent(btnGoToDashboard, Self, 'OnClick', 'btnGoToDashboardClick');
    SetEvent(btnGoToDashboard, Self, 'OnMouseEnter', 'btnDashboardMouseEnter');
    SetEvent(btnGoToDashboard, Self, 'OnMouseLeave', 'btnDashboardMouseLeave');
    WebLabel11.SetParentComponent(btnGoToDashboard);
    WebLabel11.Name := 'WebLabel11';
    WebLabel11.AlignWithMargins := True;
    WebLabel11.Left := 49;
    WebLabel11.Top := 3;
    WebLabel11.Width := 278;
    WebLabel11.Height := 37;
    WebLabel11.Margins.Right := 0;
    WebLabel11.Margins.Bottom := 0;
    WebLabel11.Align := alClient;
    WebLabel11.Caption := 'Go To Dashboard';
    WebLabel11.ElementFont := efCSS;
    WebLabel11.Font.Charset := DEFAULT_CHARSET;
    WebLabel11.Font.Color := clBlack;
    WebLabel11.Font.Height := -21;
    WebLabel11.Font.Name := 'Segoe UI';
    WebLabel11.Font.Style := [];
    WebLabel11.HeightStyle := ssAuto;
    WebLabel11.HeightPercent := 100.000000000000000000;
    WebLabel11.HTMLType := tSPAN;
    WebLabel11.Layout := tlCenter;
    WebLabel11.ParentFont := False;
    WebLabel11.WidthPercent := 100.000000000000000000;
    SetEvent(WebLabel11, Self, 'OnClick', 'btnGoToDashboardClick');
    WebHTMLDiv13.SetParentComponent(btnGoToDashboard);
    WebHTMLDiv13.Name := 'WebHTMLDiv13';
    WebHTMLDiv13.AlignWithMargins := True;
    WebHTMLDiv13.Left := 3;
    WebHTMLDiv13.Top := 0;
    WebHTMLDiv13.Width := 40;
    WebHTMLDiv13.Height := 40;
    WebHTMLDiv13.Margins.Top := 0;
    WebHTMLDiv13.Margins.Bottom := 0;
    WebHTMLDiv13.WidthStyle := ssAuto;
    WebHTMLDiv13.Align := alLeft;
    WebHTMLDiv13.ChildOrder := 2;
    WebHTMLDiv13.ElementFont := efCSS;
    WebHTMLDiv13.HTML.BeginUpdate;
    try
      WebHTMLDiv13.HTML.Clear;
      WebHTMLDiv13.HTML.Add('<svg xmlns="http://www.w3.org/2000/svg" height="24" width="24" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 ');
      WebHTMLDiv13.HTML.Add('256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"/></svg>');
    finally
      WebHTMLDiv13.HTML.EndUpdate;
    end;
    WebHTMLDiv13.Role := '';
    SetEvent(WebHTMLDiv13, Self, 'OnClick', 'btnGoToDashboardClick');
    XDataWebClient.SetParentComponent(Self);
    XDataWebClient.Name := 'XDataWebClient';
    XDataWebClient.Connection := APIIdentification;
    SetEvent(XDataWebClient, Self, 'OnError', 'XDataWebClientError');
    SetEvent(XDataWebClient, Self, 'OnRequest', 'XDataWebClientRequest');
    XDataWebClient.Left := 1369;
    XDataWebClient.Top := 475;
    APIIdentification.SetParentComponent(Self);
    APIIdentification.Name := 'APIIdentification';
    APIIdentification.URL := '/empire/sideminds';
    APIIdentification.DesignData.Headers.BeginUpdate;
    try
      APIIdentification.DesignData.Headers.Clear;
      APIIdentification.DesignData.Headers.Add('tenant-url=http://localhost:8000/AppzoolaWeb/');
    finally
      APIIdentification.DesignData.Headers.EndUpdate;
    end;
    APIIdentification.DesignData.Persist := True;
    SetEvent(APIIdentification, Self, 'OnError', 'APIIdentificationError');
    SetEvent(APIIdentification, Self, 'OnRequest', 'APIIdentificationRequest');
    APIIdentification.Left := 1249;
    APIIdentification.Top := 422;
    WebHttpRequest1.SetParentComponent(Self);
    WebHttpRequest1.Name := 'WebHttpRequest1';
    SetEvent(WebHttpRequest1, Self, 'OnResponse', 'WebHttpRequest1Response');
    WebHttpRequest1.Left := 1312;
    WebHttpRequest1.Top := 236;
    GetUser.SetParentComponent(Self);
    GetUser.Name := 'GetUser';
    GetUser.Command := httpPOST;
    GetUser.URL := 'https://sideminds.com/empire/sideminds/SidemindsService/GetUser';
    GetUser.Left := 1312;
    GetUser.Top := 144;
    XDataWebClient1.SetParentComponent(Self);
    XDataWebClient1.Name := 'XDataWebClient1';
    XDataWebClient1.Left := 648;
    XDataWebClient1.Top := 286;
    CreateSubscription.SetParentComponent(Self);
    CreateSubscription.Name := 'CreateSubscription';
    CreateSubscription.Command := httpPOST;
    CreateSubscription.URL := 'https://sideminds.com/empire/sideminds/SidemindsService/createsubscription';
    CreateSubscription.Left := 1424;
    CreateSubscription.Top := 246;
  finally
    layLeftNavContainer.AfterLoadDFMValues;
    WebPanel5.AfterLoadDFMValues;
    WebPanel6.AfterLoadDFMValues;
    lblLogin2.AfterLoadDFMValues;
    imgTesting.AfterLoadDFMValues;
    WebPanel7.AfterLoadDFMValues;
    lblTMLCReviews2.AfterLoadDFMValues;
    WebHTMLDiv2.AfterLoadDFMValues;
    WebPanel8.AfterLoadDFMValues;
    lblTMLCAssessment2.AfterLoadDFMValues;
    WebHTMLDiv3.AfterLoadDFMValues;
    WebPanel9.AfterLoadDFMValues;
    lblTheMarketingLeadersCodeBook2.AfterLoadDFMValues;
    WebHTMLDiv4.AfterLoadDFMValues;
    WebPanel10.AfterLoadDFMValues;
    lblOurExperience2.AfterLoadDFMValues;
    WebHTMLDiv5.AfterLoadDFMValues;
    WebPanel11.AfterLoadDFMValues;
    lblOurApproach2.AfterLoadDFMValues;
    WebHTMLDiv6.AfterLoadDFMValues;
    WebPanel12.AfterLoadDFMValues;
    lblContact2.AfterLoadDFMValues;
    WebHTMLDiv7.AfterLoadDFMValues;
    WebPanel13.AfterLoadDFMValues;
    lblBlog2.AfterLoadDFMValues;
    WebHTMLDiv8.AfterLoadDFMValues;
    WebPanel14.AfterLoadDFMValues;
    WebHTMLDiv9.AfterLoadDFMValues;
    TopBar.AfterLoadDFMValues;
    imgLogo.AfterLoadDFMValues;
    lblLogin.AfterLoadDFMValues;
    lblTheMarketingLeadersCodeBook.AfterLoadDFMValues;
    lblTMLCAssessment.AfterLoadDFMValues;
    lblTMLCReviews.AfterLoadDFMValues;
    lblBlog.AfterLoadDFMValues;
    lblContact.AfterLoadDFMValues;
    lblOurApproach.AfterLoadDFMValues;
    lblOurExperience.AfterLoadDFMValues;
    lblWelcomeUser.AfterLoadDFMValues;
    imgProfilePicture.AfterLoadDFMValues;
    btnHamburgerMenu.AfterLoadDFMValues;
    btnApply.AfterLoadDFMValues;
    btnSave.AfterLoadDFMValues;
    txtAddress.AfterLoadDFMValues;
    txtCity.AfterLoadDFMValues;
    txtCompanyName.AfterLoadDFMValues;
    txtCountry.AfterLoadDFMValues;
    txtEmail.AfterLoadDFMValues;
    txtFirstName.AfterLoadDFMValues;
    txtJobTitle.AfterLoadDFMValues;
    txtMobile.AfterLoadDFMValues;
    txtPassword.AfterLoadDFMValues;
    txtPromoCode.AfterLoadDFMValues;
    txtReTypePassword.AfterLoadDFMValues;
    txtSeniority.AfterLoadDFMValues;
    txtState.AfterLoadDFMValues;
    txtSurname.AfterLoadDFMValues;
    txtZip.AfterLoadDFMValues;
    layHeaderProfileDropDown.AfterLoadDFMValues;
    layNavSignOut.AfterLoadDFMValues;
    btnSignOut.AfterLoadDFMValues;
    WebLabel10.AfterLoadDFMValues;
    WebHTMLDiv12.AfterLoadDFMValues;
    layNavProfile.AfterLoadDFMValues;
    btnGoToDashboard.AfterLoadDFMValues;
    WebLabel11.AfterLoadDFMValues;
    WebHTMLDiv13.AfterLoadDFMValues;
    XDataWebClient.AfterLoadDFMValues;
    APIIdentification.AfterLoadDFMValues;
    WebHttpRequest1.AfterLoadDFMValues;
    GetUser.AfterLoadDFMValues;
    XDataWebClient1.AfterLoadDFMValues;
    CreateSubscription.AfterLoadDFMValues;
  end;
end;

end.
